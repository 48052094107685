import React, { useState } from "react";
import loginimagere2 from "../../assets/images/loginimagere2.png";
import { Button, Input } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../nav/Header";
import { sendOtpMobile } from "../../functions/auth";
import toast from "react-hot-toast";

import { login } from "../../app/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
const LoginWithMobile = () => {
  const [mobile, setMobile] = useState("");
  const [visible, setVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submit = async () => {
    try {
      const response = await sendOtpMobile(mobile);
      console.log(response)
      if (response.message === "success") {
        setVisible(true);
        return;
      }
  
      if (response.message === "Please try Again") {
        setVisible(false);
        toast.error(response.message);
        return;
      }
      
    } catch (error) {
      toast.error(error.response.data.error.message)
    }
 
  };

  const { status } = useSelector((state) => state.user);
  let location = useLocation();

  const handleLogin = async () => {
    const res = await dispatch(login({ mobile, otp }));
    if (res.payload.user) {
      roleBasedRedirect(res.payload);
    }
  };

  const roleBasedRedirect = (data) => {
    const intended = location.state?.from;
    if (intended) {
      navigate(intended);
    } else {
      if (data.user?.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <>
      <Header />

      <div className="w-full h-[100vh] flex flex-col justify-between gap-10">
        <div className="flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font">
          <span className="text-lg text-white">Welcome to</span>
          <h1 className="text-3xl text-white ">Thuhina.lk</h1>
        </div>

        <div className="flex justify-center w-full">
          <div className="relative rounded-lg shadow-lg w-[98%] sm:w-[80%]  md:w-[60%] lg:w-[50%] flex bg-[#F9F9F9]">
            <img src={loginimagere2} alt="/" className="md:flex" />

            <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full gap-5 py-10">
              <h1 className="text-[#3B3B3B] text-center text-2xl font-medium ">
                Welcome !
              </h1>

              {visible ? (
                <>
                  <div className="flex flex-col gap-3 px-3 md:px-20 items-center">
                    <span className="text-[#3B3B3B]">
                      Enter your OTP code that we send to your mobile
                    </span>
                    <div className="flex gap-5">
                      <Input
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center w-full">
                    <div className="w-1/3 md:w-1/5">
                      <Button
                        variant="filled"
                        color="#EACC00"
                        className="text-black"
                        fullWidth
                        onClick={() => handleLogin()}
                      >
                        {status === "loading" ? (
                          <PulseLoader color="#fffff" size={5} />
                        ) : (
                          "verify"
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="flex flex-col gap-3 px-3 md:px-20 items-center">
                    <span className="text-[#3B3B3B]">
                      Enter Your Mobile Number Here
                    </span>
                    <span className="text-[#3B3B3B]">
                    (ඔබගේ ජංගම දුරකථන අංකය මෙහි ඇතුලත් කරන්න)
                    </span>
                    <div className="flex gap-5">
                      <input
                        type="text"
                        className="w-full bg-transparent border-b outline-none "
                        placeholder="Ex: 0787654076"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center w-full">
                    <div className="w-1/3 md:w-1/5">
                      <Button
                        variant="filled"
                        color="#EACC00"
                        className="text-black"
                        onClick={() => submit()}
                      >
                        Send Code
                      </Button>
                    </div>
                  </div>
                </>
              )}

              <div className="flex flex-col gap-1">
                <Link to="/login/email">
                  <div className="w-full text-center">
                    <span className="text-[#3B3B3B] underline text-center text-sm">
                      Log In With Email Verification
                    </span>
                  </div>
                </Link>
                {/* <Link to="/login/username">
                  <div className="w-full text-center">
                    <span className="text-[#3B3B3B] underline text-center text-sm">
                      Log In With Password
                    </span>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full py-5 text-center text-[#595959]">
          <span className="">By signing up you are agreeing to our</span>
          <div className="flex justify-center w-full gap-1 text-center">
            <Link to="/privacy">
              <span>Privacy Policy </span>
            </Link>
            <span>and</span>
            <Link to="/terms&conditions">
              <span>Terms of Use.</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginWithMobile;
