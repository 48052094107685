import React, { useEffect, useState, useCallback } from 'react';
import EmptyAdd from '../../components/advertisement/Empty';
import MyAdd from '../../components/advertisement/MyAdd';
import { useSelector } from 'react-redux';
import { getMyAddvertisment } from '../../functions/addvertismentt';

const Advertisement = () => {
  const { user } = useSelector((state) => state.user);
  const [myadd, setMyadd] = useState({});
  const [loading, setLoading] = useState(false);

  const loadadd = useCallback(async () => {
    setLoading(true);
    
    const response = await getMyAddvertisment(user.token);
    setMyadd(response);
    setLoading(false);
  }, [user.token]);

  useEffect(() => {
    loadadd();
  }, [loadadd]);


  return (
    <div className='flex flex-col px-3 py-10 md:px-10 lg:px-16 xl:px-24'>
      <div className='w-full bg-[#E8B202] py-5 shadow-xl rounded-md'>
        <h1 className='text-white  text-2xl font-medium text-center'>
          {loading ? 'loading ....' : 'My Advertisement'}
        </h1>
      </div>
      {!myadd ? <EmptyAdd /> : <MyAdd myadd={myadd} />}
    </div>
  );
};

export default Advertisement;
