import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const AUTH_ENDPOINT = `${process.env.REACT_APP_API}/auth`;

const initialState = {
  status: "",
  error: "",
  user: {
    id: "",
    first_name: "",
    last_name: "",
    mobile: "",
    gender: "",
    verify: "",
    email: "",
    role: "",
    token: "",
  },
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, otp, mobile }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`${AUTH_ENDPOINT}/login`, {
        mobile,
        email,
        otp,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.error.message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (
    {
      first_name,
      last_name,
      email,
      mobile,
      idImg,
      gender,
      country,
      state
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(`${AUTH_ENDPOINT}/register`, {
        first_name,
        last_name,
        email,
        mobile,
        idImg,
        gender,
        country,
        state
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.status = "";
      state.error = "";
      state.user = initialState.user;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout, changeStatus } = userSlice.actions;
export default userSlice.reducer;
