import React from 'react'
import { Link } from 'react-router-dom'


const Privacy2 = () => {
    return (
        <>
            <div className='flex flex-col w-full gap-10 pb-10 mb-12'>

                <div className='flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font'>
                    <h1 className='text-4xl text-white font-bold'> Privacy Policy</h1>
                    <span className='text-lg text-white'>Lastly Updated on 3 / 4 / 2024</span>
                </div>

                <div className='flex flex-col gap-10 px-3 md:px-10 lg:px-16 xl:px-24'>
                    <div className='flex flex-col gap-7'>
                        <h1 className='text-lg font-medium'>රහස්‍යතා ප්‍රතිපත්ති:</h1>
                        <p className='text-[#696969]'>අපගේ වෙබ් අඩවිය තුලින් සපයන ලබන විවිධ සේවාවන් ලබා ගැනිමට පැමිණෙන ඔබට සහ පරිශීලකයන්ට ඉහල ගුණාත්මකභාවයක් සහ නිවැද්‍යතාවය පිරි සේවයක් ලබා දිමට ඔබගෙ පෞද්ගලික අපි ලබා ගන්නෙමු. එමෙන්ම ඔබ අප කෙරෙහි තබන විස්වාසය ආරක්ෂා කරීමට අපි කැපවෙන්නෙමු .</p>
                        <p className='text-[#696969]'>විද්‍යුත් ලිපිනය, සම්පුර්ණ නම, දුරකථන අංකය , හැදුනුම්පත, තැපැල් ලිපිනය ,උපන්දිනය, උස, සිවිල් තත්වය සහ ඊට සීමා නොවි ඔබ සපයන ලබන අනෙකුත් පෞද්ගලික තොරතුරු සහ දත්ත. මෙය පුද්ගල දත්ත නිවරද්‍යතාව තහාවුරු කරගැනීමට thuhina.lk  වෙතින් ලබා ගන්නා තොරතුරු වේ.</p>
                        <p className='text-[#696969]'>අප ඔබගේ පණිවිඩ හුවමාරු දත්ත සටහන් ද thuhina.lk තුල ගබඩා කරගනු ලබයි,එය කිසිදු පාර්ශයකට ලබා ගත හො ලබා දිමට thuhina.lk අපි බැදි නොමැති අතර පවතින නිතියේ අවශ්‍යතාවය මත පමණක් ලබා දිමට පමණක් අපි බැදි සිටින්නෙමු.</p>
                        <p className='text-[#696969]'>අපගේ ගනුදෙනුකරුවන් ලබා දුන් තොරතුරු උපරිම සැලකිලිමත්භාවයක් යටතෙ ඉහල ආරක්ෂාවක් මත thuhina.lk සමාජිකයින් සමග ගුණාත්මකභාවයක් පිරි විස්වාසනීය සේවයක් ලබා දිමට පමණක් හුවමාරු කර ගනී.</p>
                        <p className='text-[#696969]'>ඔබ විසින් සපයන ලබන සියලුම තොරතුරු ආරක්ෂිත පද්ධතියක් තුල බලයලත් පුද්ගලයන්ට පමණක් ඇතුල් විය හැකි ස්ථානයක ගබඩා කරනු ලබයි.ඔබගෙ පෞද්ගලික තොරතුරු වලට උපරිම ආරක්ෂාවක් thuhina.lk වෙතින් සපයා ඇත.</p>
                        <p className='text-[#696969]'>පෞද්ගලිකත්ව ප්‍රතිපත්ති thuhina.lk තනි අභිමතය පරිදි සහ දැනුවත් කිරිමකින් තොරව කලින් කලට සංශෝධනය කර යාවත් කාලීන කල හැකිය.thuhina .lk තුල සංශෝධිත පෞද්ගලිකත්ව ප්‍රතිපත්ති පල කල විගස එය ක්‍රියාත්මක වන අතර එතැන් පටන් යම් අයෙක් වෙබ් අඩවිය භාවිතා කරයි නම් සංශෝධිත පෞද්ගලිකත්ව ප්‍රතිපත්ති ඔබ විසින් පිලිගැනිමක් ලෙස සලකනු ලැබේ.</p>


                        <Link to="/privacy" className='underline'>Translate to English</Link>
                    </div>
                    
                </div>


            </div>
        </>
    )
}

export default Privacy2;
