import React from 'react'
// import Bg_top from '../../assets/images/bg_image_top.png'
// import Bg_bottom from '../../assets/images/bg_image_bottom.png'
import loginimagere2 from "../../assets/images/loginimagere2.png";

import { Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import Header from '../nav/Header'

const LoginWithUsername = () => {
    return (
        <>

            <Header />

            <div className='w-full h-[100vh] flex flex-col justify-between gap-10'>

                <div className='flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font'>
                    <span className='text-lg text-white'>Welcome to</span>
                    <h1 className='text-3xl text-white '>Thihina Mangala Sewaya</h1>
                </div>

                <div className='flex justify-center w-full'>
                    <div className='relative rounded-lg shadow-lg w-[98%] sm:w-[80%]  md:w-[60%] lg:w-[50%]  flex bg-[#F9F9F9]'>
                        {/* <img src={Bg_top} alt='/' />
                        <img src={Bg_bottom} alt='/' className='hidden md:flex' /> */}

<img src={loginimagere2} alt="/" className="md:flex" />

                        <div className='absolute top-0 left-0 flex flex-col justify-center w-full h-full gap-5 py-10 items-center'>
                            <h1 className='text-[#3B3B3B] text-center text-2xl font-medium'>Welcome Back !</h1>
                            <div className='flex flex-col gap-3 px-3 md:px-20'>
                                <span className='text-[#3B3B3B]'>Username</span>
                                <div className='flex'>
                                    <input type='email' className='w-full bg-transparent border-b outline-none' placeholder='Ex: malindu@gmail.com' />
                                </div>
                            </div>
                            <div className='flex flex-col gap-3 px-3 md:px-20'>
                                <span className='text-[#3B3B3B]'>Password</span>
                                <div className='flex'>
                                    <input type='password' className='w-full bg-transparent border-b outline-none' placeholder='password' />
                                </div>
                            </div>

                            <div className='flex justify-center w-full'>
                                <div className='w-1/3 md:w-1/5'>
                                    <Button variant='filled' color='#EACC00' className='text-black' fullWidth>Verify</Button>
                                </div>
                            </div>

                            <div className='flex flex-col gap-1'>
                                <Link to='/login/mobile'><div className='w-full text-center'><span className='text-[#3B3B3B] underline text-center text-sm'>Log In With Mobile Verification</span></div></Link>
                                <Link to='/login/email'><div className='w-full text-center'><span className='text-[#3B3B3B] underline text-center text-sm'>Log In With Email Verification</span></div></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col w-full py-5 text-center text-[#595959]'>
                    <span className=''>By signing up you are agreeing to our</span>
                    <div className='flex justify-center w-full gap-1 text-center'>
                        <Link to='/privacy'><span>Privacy Policy </span></Link>
                        <span>and</span>
                        <Link to='/terms&conditions'><span>Terms of Use.</span></Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LoginWithUsername
