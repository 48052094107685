import React from "react";
import moment from "moment";



const MagazineCard = ({ m }) => {


  return (
    <div className="flex flex-col items-center justify-center shadow-lg">
      <div className="relative flex items-center justify-center overflow-hidden duration-300 group">
        <img
          src={m.image[0].imageUrl}
          alt="/"
          className="w-full duration-300 group-hover:scale-110"
        />
        <div className="absolute flex items-center justify-center w-full h-full duration-300 bg-black/40 bottom-full group-hover:top-0 group-hover:left-0">


          <a
            href={m.pdf.url}
          >            
          <button
            class="bg-[#E8B202] text-white border border-yellow-500 hover:bg-yellow-600 hover:border-yellow-600 px-4 py-2 rounded"
          >
              View & Download
            </button>
          </a>


        </div>
      </div>
      <div className="py-5 text-center">
        <h1>{moment(m.creatdAt).format("MMM Do YY")} </h1>
      </div>
    </div>
  );
};

export default MagazineCard;
