import axios from "axios";
import toast from "react-hot-toast";

export const getPremuimesAll = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/user/premiums`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};



export const deletePremuim = async (token,premiumId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API}/admin/premium/${premiumId}`,null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const cratePremuim = async (token,name,price) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/premium`,
      {name,price},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};



