import React, { useCallback, useEffect, useState } from "react";
import Bg_top from "../../assets/images/bg_image_top.png";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { FaBirthdayCake } from "react-icons/fa";
import { MdTempleHindu } from "react-icons/md";
import { MdHeartBroken } from "react-icons/md";
import { GiBodyHeight } from "react-icons/gi";
import { MdEngineering } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { FaUserGraduate } from "react-icons/fa6";
import girl from "../../assets/images/woman.png";
import boy from "../../assets/images/male.png";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "country-state-city";
import ReactCountryFlag from "react-country-flag";
const Card = ({ myadd }) => {
  const today = moment();
  const birthday = moment(myadd.bod);
  const years = today.diff(birthday, "years");

  const { user } = useSelector((state) => state.user);

  const [state, setState] = useState("");

  const getState = useCallback(async () => {
    const state = State.getStateByCodeAndCountry(
      myadd?.residency?.state,
      myadd.residency?.country
    );
    if(state){
      setState(state.name)
    }
   

 
  }, [myadd?.residency?.state, myadd.residency?.country]);
  useEffect(() => {
    getState();
  }, [getState]);

  return (
    <>
      {myadd.premium > 0 ? (
        <div
          className={`relative w-full  md:w-[80%] md:ml-[10%] rounded-b-3xl bg-gradient-to-r from-[#f3d17d]  to-[#f9f9f9] border-2 shadow-md `}
        >
          <img src={Bg_top} alt="/" className=" w-80 md:h-[280px] h-[320px]" />
          <div className="absolute top-0 left-0 z-50 flex flex-col w-full h-full gap-5 p-3 md:pt-0 md:pl-5 md:pr-5">
            <div className="flex flex-col justify-between w-full gap-2 md:items-center md:flex-row">
              <div className="flex items-center gap-3">
                <span className="text-xl font-bold">
                  {myadd.first_name} {myadd.last_name}
                </span>

                <div className={`flex items-center gap-1 ctext-red-600`}>
                  {myadd?.user?.usertype === "Verified" ? (
                    <AiFillSafetyCertificate />
                  ) : null}
                  <span>{myadd?.user?.usertype}</span>
                </div>

                <div className="flex ">
                  {myadd.residency?.country ? (
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={myadd.residency?.country}
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label="United States"
                    />
                  ) : null}
                </div>
              </div>
              <span className="text-[#6C6C6C]">
                {moment(myadd.createdAt).fromNow()}
              </span>
            </div>
            <div className="grid w-full grid-cols-2 gap-2 text-sm md:text-[16px] md:gap-6 md:grid-cols-4">
              <div className="flex items-center gap-2">
                <FaLocationDot />
                <span>{state ? state : null}</span>
              </div>
              <div className="flex items-center gap-2">
                <FaBirthdayCake />
                <span> {years} Years Old</span>
              </div>
              <div className="flex items-center gap-2">
                <MdTempleHindu />
                <span>{myadd.religion}</span>
              </div>
              <div className="flex items-center gap-2">
                <MdHeartBroken />
                <span>{myadd.civilstatus}</span>
              </div>
              <div className="flex items-center gap-2">
                <GiBodyHeight />
                <span>{myadd.height / 10}ft</span>
              </div>
              <div className="flex items-center gap-2">
                <MdEngineering />
                <span>{myadd?.education_profession?.profession}</span>
              </div>
              <div className="flex items-center gap-2">
                <IoIosPeople />
                <span>Bodu Govi</span>
              </div>
              <div className="flex items-center gap-2">
                <FaUserGraduate />
                <span>{myadd?.education_profession?.education}</span>
              </div>
            </div>
            <div className="flex items-center justify-between w-full gap-3">
              <div className="flex items-center gap-3">
                {myadd.gender === "Male" ? (
                  <img
                    src={boy}
                    alt="/"
                    className="rounded-full h-11 w-11 sm:h-15 sm:w-15 lg:w-20 lg:h-20"
                  />
                ) : (
                  <img
                    src={girl}
                    alt="/"
                    className="rounded-full h-11 w-11 sm:h-15 sm:w-15  lg:w-20 lg:h-20"
                  />
                )}
              </div>
              {user.token ? (
                <>
                  {user.id === myadd?.user?._id ? (
                    <>
                      {" "}
                      <Link to="/user/my/add">
                        <Button variant="filled" color="#EACC00">
                          More Details
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to={`/addvertisment/${myadd._id}`}>
                        <Button variant="filled" color="#EACC00">
                          More Details
                        </Button>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <Link to={`/addvertisment/${myadd._id}`}>
                  <Button variant="filled" color="#EACC00">
                    More Details
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`relative w-full  md:w-[80%] md:ml-[10%] rounded-b-3xl bg-gradient-to-r  to-[#f9f9f9] border-2 shadow-md `}
        >
          <img src={Bg_top} alt="/" className=" w-80 md:h-[280px] h-[320px]" />
          <div className="absolute top-0 left-0 z-50 flex flex-col w-full h-full gap-5 p-3 md:pt-0 md:pl-5 md:pr-5">
            <div className="flex flex-col justify-between w-full gap-2 md:items-center md:flex-row">
              <div className="flex items-center gap-3">
                <span className="text-xl font-bold">
                  {myadd.first_name} {myadd.last_name}
                </span>

                <div className={`flex items-center gap-1 ctext-red-600`}>
                  {myadd?.user?.usertype === "Verified" ? (
                    <AiFillSafetyCertificate />
                  ) : null}
                  <span>{myadd?.user?.usertype}</span>
                </div>
                <div className="flex ">
                  <ReactCountryFlag
                    className="emojiFlag"
                    countryCode={myadd.residency?.country}
                    style={{
                      fontSize: "2em",
                      lineHeight: "2em",
                    }}
                    aria-label="United States"
                  />
                </div>
              </div>
              <span className="text-[#6C6C6C]">
                {moment(myadd.createdAt).fromNow()}
              </span>
            </div>
            <div className="grid w-full grid-cols-2 gap-2 text-sm md:text-[16px] md:gap-6 md:grid-cols-4">
              <div className="flex items-center gap-2">
                <FaLocationDot />
                <span>{state}</span>
              </div>
              <div className="flex items-center gap-2">
                <FaBirthdayCake />
                <span> {years} Years Old</span>
              </div>
              <div className="flex items-center gap-2">
                <MdTempleHindu />
                <span>
                  {" "}
                  <span>{myadd.religion}</span>
                </span>
              </div>
              <div className="flex items-center gap-2">
                <MdHeartBroken />
                <span>{myadd.civilstatus}</span>
              </div>
              <div className="flex items-center gap-2">
                <GiBodyHeight />
                <span>{myadd.height / 10}ft</span>
              </div>
              <div className="flex items-center gap-2">
                <MdEngineering />
                <span>{myadd?.education_profession?.profession}</span>
              </div>
              <div className="flex items-center gap-2">
                <IoIosPeople />
                <span>{myadd.caste}</span>
              </div>
              <div className="flex items-center gap-2">
                <FaUserGraduate />
                <span>{myadd?.education_profession?.education}</span>
              </div>
            </div>
            <div className="flex items-center justify-between w-full gap-3">
              {myadd.gender === "Male" ? (
                <img
                  src={boy}
                  alt="/"
                  className="rounded-full h-11 w-11 sm:h-15 sm:w-15 lg:w-20 lg:h-20"
                />
              ) : (
                <img
                  src={girl}
                  alt="/"
                  className="rounded-full h-11 w-11 sm:h-15 sm:w-15  lg:w-20 lg:h-20"
                />
              )}

              {user.token ? (
                <>
                  {user.id === myadd?.user?._id ? (
                    <>
                      {" "}
                      <Link to="/user/my/add">
                        <Button variant="filled" color="#EACC00">
                          More Details
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to={`/addvertisment/${myadd._id}`}>
                        <Button variant="filled" color="#EACC00">
                          More Details
                        </Button>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <Link to={`/addvertisment/${myadd._id}`}>
                  <Button variant="filled" color="#EACC00">
                    More Details
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
