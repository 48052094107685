import React from 'react';

const Success = () => {
    return (
        <div className="flex items-center justify-center py-5 md:py-10  bg-[#EBF0F5]">
            <div className="bg-white p-16 rounded shadow-md inline-block text-center">
                <div className="flex items-center justify-center h-48 w-48 bg-[#F8FAF5] rounded-full mx-auto">
                    <i className="text-[#9ABC66] text-9xl leading-[200px]">✓</i>
                </div>
                <h1 className="text-[#88B04B] font-bold text-4xl mt-4">Success</h1>
                <p className="text-[#404F5E] text-xl mt-2">Your purchase request has been completed.<br /> we'll be in touch shortly!</p>
            </div>
        </div>
    );
};

export default Success;
