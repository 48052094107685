import { Button, Input } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  cratePremuim,
  deletePremuim,
  getPremuimesAll,
} from "../../../functions/premium";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const TopAds = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [premuims, setPremums] = useState([]);

  const { user } = useSelector((state) => state.user);

  const loadAllPremums = useCallback(async () => {
    const response = await getPremuimesAll();
    setPremums(response)
  }, []);
  useEffect(() => {
    loadAllPremums();
  }, [loadAllPremums]);

  const sumit = async () => {
    const response = await cratePremuim(user.token, name, price);
    toast.success(response.message);
    loadAllPremums()
    setName("");
    setPrice("");
  };

  const deletePremuims = async (premiumId) => {
    const response = await deletePremuim(user.token, premiumId);
    toast.success(response.message);
    loadAllPremums();
  };

  return (
    <div>
      <div className="flex flex-col gap-10">
        <h1 className="text-xl font-medium">Top Ads</h1>

        <div className="flex flex-col gap-10">
          <div className="flex items-center gap-3">
            <span>Price (Rs.):</span>
            <Input
              placeholder="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              placeholder="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Button variant="filled" color="#EACC00" onClick={() => sumit()}>
              Update
            </Button>
          </div>
          {premuims.map((p) => (
            <div className="flex flex-col w-100% md:w-[45%] xl:w-[30%] gap-5 bg-slate-300 p-3 rounded-md">
              <span>{p.name}</span>
              <span>{p.price}</span>
              <Button
                variant="filled"
                
                color="red"
                onClick={() => deletePremuims(p._id)}
              >
                {" "}
                delete
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopAds;
