import React, { useCallback, useEffect, useState } from "react";
import mainbackimage from "../../assets/images/mainbackimage.png";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { Button } from "@mantine/core";
import { useSelector } from "react-redux";
import { myProfile, myProfileUpdate } from "../../functions/auth";
import toast from "react-hot-toast";


const MyProfile = () => {
  const [profile, setProfile] = useState({});

  const [first_name, setFisrtName] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [idImg, setIdImg] = useState([]);


  const { user } = useSelector((state) => state.user);



  const loadMyProfile = useCallback(async () => {
    const response = await myProfile(user.token);
    setProfile(response);
    setFisrtName(response.first_name);
    setLastname(response.last_name);
    setMobile(response.mobile);
    setEmail(response.email);
    setGender(response.gender);
    setIdImg(response.nic);
  }, [user.token]);


  useEffect(() => {
    loadMyProfile();
  }, [loadMyProfile]);





  const sunmit = async () => {
    const response = await myProfileUpdate(user.token, first_name, last_name, email, mobile, idImg);
    toast.success(response.message)
    loadMyProfile();
  }

  return (
    <div
      className="w-full"
      style={{ backgroundImage: `url(${mainbackimage})` }}
    >
      <div className="flex justify-center py-10">
        <div className="flex flex-col md:flex-row w-full    lg:w-2/3 bg-white md:p-10 p-3 gap-10 shadow-md rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-medium md:hidden">
            My Profile
          </h1>
          <div className="flex flex-col items-center w-full gap-2 md:w-1/5">
            {/* <img
              src={}
              alt="/"
              className="w-32 h-32 border rounded-full p-1"
            /> */}
            {/* verifiy eka thibba thana */}

          </div>
          <div className="flex flex-col w-full h-full gap-10 md:w-4/5">
            <h1 className="text-[#3B3B3B] text-center text-2xl font-medium hidden md:flex ">
              My Profile
            </h1>
            {/*  */}
            <div className="flex items-center gap-1 text-blue-600">
              <AiFillSafetyCertificate />
              <span>{profile.usertype}</span>
            </div>
            {/*  */}
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-6 md:gap-10 md:flex-row">
                <div className="flex flex-col gap-2 px-3 md:w-1/2">
                  <span className="text-[#3B3B3B]  ">First Name</span>
                  <input
                    type="text"
                    className="w-full bg-transparent border-b-2 outline-none"
                    value={first_name}
                    placeholder=" Malindu"
                    onChange={(e) => setFisrtName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3 md:w-1/2">
                  <span className="text-[#3B3B3B]">Last Name</span>
                  <input
                    type="text"
                    className="w-full bg-transparent border-b-2 outline-none"
                    placeholder="Ishan"
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 px-3">
                <span className="text-[#3B3B3B]">Mobile Number</span>
                <div className="flex gap-5">
                  <input
                    type="text"
                    className="w-full bg-transparent border-b-2 outline-none"
                    placeholder="+94 71 23 45 678"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 px-3">
                <span className="text-[#3B3B3B]">Email Address</span>
                <div className="flex gap-5">
                  <input
                    type="text"
                    className="w-full bg-transparent border-b-2 outline-none"
                    placeholder="malindu@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 px-3">
                <span className="text-[#3B3B3B]">{gender}</span>
                <div className="flex gap-5">

                </div>
              </div>
              {/* <div className="flex flex-col gap-6 md:gap-10 md:flex-row">
                <div className="flex flex-col gap-2 px-3 md:w-1/2">
                  <span className="text-[#3B3B3B]  ">NIC Images</span>
                </div>
              </div> */}
         
           
              <div className="flex justify-end w-full mt-10 gap-x-5">
                <Button variant="filled" color="#EACC00" onClick={() => sunmit()}>
                  Save Details
                </Button>

               

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
