import React, { useCallback, useEffect, useState } from "react";
import Hero from "../components/home/Hero";
import { Button, Checkbox, Drawer, Group, Radio, Select } from "@mantine/core";
import { Accordion } from "@mantine/core";
import { FaMale } from "react-icons/fa";
import { FaFemale } from "react-icons/fa";
import { useDisclosure } from "@mantine/hooks";
import { Country, State } from "country-state-city";
import { Slider } from "antd";

import {
  feacthAddvertsiment,
  getAddvertsimentHome,
} from "../functions/addvertismentt";
import Card from "../components/cards/Card";
const Home = () => {



  const [opened, { open, close }] = useDisclosure(false);
  const [add, setAdd] = useState([]);
  const [page, setPage] = useState(20)


  const getHome = useCallback(async () => {
    const response = await getAddvertsimentHome(page);
    setAdd(response);
  }, [page]);

  useEffect(() => {
    getHome();
  }, [getHome]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  /*Gender */
  const handleLoadMore = () => {
    let pages = page + 20;
    setPage(pages);
    getHome();
  };


  const countries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));

  const states = State.getStatesOfCountry(country).map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));
  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
  };
  const handleStateChange = (selectedState) => {
    setState(selectedState);
  };
  const fetchData = async (arg) => {
    const response = await feacthAddvertsiment(arg);
    setAdd(response);
  };

  const changegender = async (value) => {
    await fetchData({ gender: value });
  };

  const changegEthencity = async (value) => {
    await fetchData({ ethnicity: value });
  };

  const changegReligion = async (value) => {
    await fetchData({ religion: value });
  };

  const changesivilstatus = async (value) => {
    await fetchData({ civilstatus: value });
  };

  const changefood_prferance = async (value) => {
    await fetchData({ Food_prference: value });
  };

  const changeeductation = async (value) => {
    await fetchData({ education: value });
  };
  const changeProfession = async (value) => {
    await fetchData({ profession: value });
  };

  const changeDrinking = async (value) => {
    await fetchData({ drinking: value });
  };
  const changeCountry = async (value) => {
    await fetchData({ country: value });
  };

  const changetate = async (value) => {
    await fetchData({ country: country, state: value });
  };
  const submitlocation = () => {
    window.location.reload();
  };
  const changeHeight = async (value) => {
    await fetchData({ height: value });
  };

  const chnageAge = async (value) => {
    await fetchData({ age: value });
  }
  return (
    <div className="flex flex-col gap-10 pb-10">
      <div className="hidden md:flex">
        <Hero />
      </div>

      <div
        className="flex flex-col gap-10 px-3 pt-10 md:px-10 lg:px-16 xl:px-24 md:pt-0"
        name="mainAddvertisement"
      >
        <div className="flex-col hidden gap-10 md:flex w-full  md:w-[80%] md:ml-[10%]">
          <div className="grid lg:grid-cols-4 md:gap-5 bg-[#ececec] p-3 rounded-md md:grid-cols-3 grid-cols-1 shadow-md">
            {/* Sort By */}

            {/* Are you looking for? */}
            <div className="flex flex-col gap-3 ">
              <span className="">Are you looking for?</span>
              <div className="flex justify-center w-full gap-6">
                <Radio.Group withAsterisk className="flex">
                  <Group mt="xs">
                    <Radio
                      value="Female"
                      label={<FaFemale size={25} />}
                      onChange={(e) => changegender(e.target.value)}
                    />
                    <Radio
                      value="Male"
                      label={<FaMale size={25} />}
                      onChange={(e) => changegender(e.target.value)}
                    />
                  </Group>
                </Radio.Group>
              </div>
            </div>

            {/* Age */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="age">
                  <Accordion.Control>Age</Accordion.Control>
                  <Accordion.Panel>
                    <div className="grid grid-cols-3 gap-3 text-center w-full">
                      <Slider min={18} max={70} onChange={(value) => chnageAge(value)} className="py-1 outline-none" />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Country of Residence */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="country">
                  <Accordion.Control>Country of Residence</Accordion.Control>
                  <Accordion.Panel>
                    <Select
                      label="Country"
                      placeholder={country}
                      onChange={(value) => {
                        handleCountryChange(value);
                        changeCountry(value);
                      }}
                      data={countries}
                      className="py-1 outline-none"
                      searchable
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Region / District */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Region">
                  <Accordion.Control>Region / District</Accordion.Control>
                  <Accordion.Panel>
                    <Select
                      label="State"
                      placeholder={state}
                      onChange={(value) => {
                        handleStateChange(value);
                        changetate(value);
                      }}
                      data={states}
                      className="py-1 outline-none"
                      searchable
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Ethnicity */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Ethnicity">
                  <Accordion.Control>Ethnicity</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Sinhalese"
                        value="Sinhalese"
                        onChange={(e) => changegEthencity(e.target.value)}
                      />
                      <Checkbox
                        label="Tamil"
                        value="Tamil"
                        onChange={(e) => changegEthencity(e.target.value)}
                      />
                      <Checkbox
                        label="Muslim"
                        value="Muslim"
                        onChange={(e) => changegEthencity(e.target.value)}
                      />
                      <Checkbox
                        label="Burger"
                        value="Burger"
                        onChange={(e) => changegEthencity(e.target.value)}
                      />
                      <Checkbox
                        label="Other"
                        value="Other"
                        onChange={(e) => changegEthencity(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Religion */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Religion">
                  <Accordion.Control>Religion</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Buddihist"
                        value="Buddihist"
                        onChange={(e) => changegReligion(e.target.value)}
                      />
                      <Checkbox
                        label="Christian"
                        value="Christian"
                        onChange={(e) => changegReligion(e.target.value)}
                      />
                      <Checkbox
                        label="Hindu"
                        value="Hindu"
                        onChange={(e) => changegReligion(e.target.value)}
                      />
                      <Checkbox
                        label="Islam"
                        value="Islam"
                        onChange={(e) => changegReligion(e.target.value)}
                      />
                      <Checkbox
                        label="Agnostic"
                        value="Agnostic"
                        onChange={(e) => changegReligion(e.target.value)}
                      />

                      <Checkbox
                        label="Catholic"
                        value="Catholic"
                        onChange={(e) => changegReligion(e.target.value)}
                      />

                      <Checkbox
                        label="None"
                        value="None"
                        onChange={(e) => changegReligion(e.target.value)}
                      />

                      <Checkbox
                        label="Other"
                        value="Other"
                        onChange={(e) => changegReligion(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Civil Status */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Civil_Status">
                  <Accordion.Control>Civil Status</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Never Married"
                        value="Never Married"
                        onChange={(e) => changesivilstatus(e.target.value)}
                      />
                      <Checkbox
                        label="Widowed"
                        value="Widowed"
                        onChange={(e) => changesivilstatus(e.target.value)}
                      />
                      <Checkbox
                        label="Divorced"
                        value="Divorced"
                        onChange={(e) => changesivilstatus(e.target.value)}
                      />
                      <Checkbox
                        label="Separated"
                        value="Separated"
                        onChange={(e) => changesivilstatus(e.target.value)}
                      />
                      <Checkbox
                        label="Other"
                        value="Other"
                        onChange={(e) => changesivilstatus(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Profession */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Profession">
                  <Accordion.Control>Profession</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Accountant"
                        value="Accountant"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Architect"
                        value="Architect"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Banker"
                        value="Banker"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Business Owner"
                        value="Business Owner"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Chef"
                        value="Chef"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Docotor"
                        value="Docotor"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Driver"
                        value="Driver"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Engineer"
                        value="Engineer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Farmer"
                        value="Farmer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Government Service"
                        value="Government Service"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Graphic Designer"
                        value="Graphic Designer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Hotelier"
                        value="Hotelier"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="IT Professional"
                        value="IT Professional"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Lawyer"
                        value="Lawyer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />

                      <Checkbox
                        label="Lecturer"
                        value="Lecturer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Manager"
                        value="Manager"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Graphic Designer"
                        value="Graphic Designer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Medical Professional"
                        value="Medical Professional"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Miltary"
                        value="Miltary"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Not Employed"
                        value="Not Employed"
                        onChange={(e) => changeProfession(e.target.value)}
                      />

                      <Checkbox
                        label="Nurse"
                        value="Nurse"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Other"
                        value="Other"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Graphic Designer"
                        value="Graphic Designer"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Quantity Surveyor"
                        value="Quantity Surveyor"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Researcher"
                        value="Researcher"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Sales Executive"
                        value="Sales Executive"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Self Employed"
                        value="Self Employed"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Quantity Surveyor"
                        value="Quantity Surveyor"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Surveyor"
                        value="Surveyor"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Teacher"
                        value="Teacher"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                      <Checkbox
                        label="Technician"
                        value="Technician"
                        onChange={(e) => changeProfession(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Food Preference */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Food_Preference">
                  <Accordion.Control>Food Preference</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Non Vegetarian"
                        value="Vagitarian"
                        onChange={(e) => changefood_prferance(e.target.value)}
                      />
                      <Checkbox
                        label="Vegetarian"
                        value="Non-vegitarian"
                        onChange={(e) => changefood_prferance(e.target.value)}
                      />
                      <Checkbox label="Vegan" />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Drinking */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Drinking">
                  <Accordion.Control>Drinking</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Yes"
                        value="Yes"
                        onChange={(e) => changeDrinking(e.target.value)}
                      />
                      <Checkbox
                        label="No"
                        value="No"
                        onChange={(e) => changeDrinking(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Mother Tongue */}

            {/* Education_Level */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Education_Level">
                  <Accordion.Control>Min Education Level</Accordion.Control>
                  <Accordion.Panel>
                    <div className="flex flex-col gap-2">
                      <select
                        className="py-1 outline-none"
                        onChange={(e) => changeeductation(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="Up to GCE O/L">Up to GCE O/L</option>
                        <option value="Up to GCE A/L">Up to GCE A/L</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Professional Qualification">
                          Professional Qualification
                        </option>
                        <option value="Undergraduate">Undergraduate</option>
                        <option value="Post Graduate Diploma">
                          Post Graduate Diploma
                        </option>
                        <option value="Master's Degree or Equivalent">
                          Master's Degree or Equivalent
                        </option>
                        <option value="Phd or Post Doctoral">
                          Phd or Post Doctoral
                        </option>
                      </select>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* Height */}
            <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Height">
                  <Accordion.Control>Height</Accordion.Control>
                  <Accordion.Panel>
                    <div className="grid grid-cols-3 gap-3 text-center">
                      <Checkbox
                        labe
                        label="4 ft"
                        value="4"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.1 ft"
                        value="4.1"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.2 ft"
                        value="4.2"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.3 ft"
                        value="4.3"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.4 ft"
                        value="4.4"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.5 ft"
                        value="4.5"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.6 ft"
                        value="4.6"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.7 ft"
                        value="4.7"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.8 ft"
                        value="4.8"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="4.9 ft"
                        value="4.9"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.1ft "
                        value="5.1"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.2 ft"
                        value="5.2"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.3 ft "
                        value="5.3"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.4ft "
                        value="5.4"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.5 ft"
                        value="5.5"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.6 ft "
                        value="5.6"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.7 ft"
                        value="5.7"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.8 ft "
                        value="5.8"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="5.9 ft "
                        value="5.9"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.0 ft"
                        value="6"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.1 ft "
                        value="6.1"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.2 ft"
                        value="6.2"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.3 ft"
                        value="6.3"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.4ft "
                        value="6.4"
                        onChange={(e) => changeHeight(e.target.value)}
                      />

                      <Checkbox
                        label="6.5ft"
                        value="6.5"
                        onChange={(e) => changeHeight(e.target.value)}
                      />

                      <Checkbox
                        label="6.6ft"
                        value="6.6"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.7ft"
                        value="6.7"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.8ft"
                        value="6.8"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="6.9 ft"
                        value="6.9"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                      <Checkbox
                        label="7 ft"
                        value="7"
                        onChange={(e) => changeHeight(e.target.value)}
                      />
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="flex justify-end mt-5 md:mt-0 md:justify-start">
              <Button
                variant="filled"
                color="#EACC00"
                onClick={() => submitlocation()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>

        <div className="flex justify-start md:hidden">
          <Button variant="filled" color="#EACC00" onClick={open}>
            Search
          </Button>
        </div>

        <Drawer
          opened={opened}
          onClose={close}
          title="Search"
          size="90%"
          position="right"
        >
          <div className="flex flex-col gap-10">
            <div className="grid lg:grid-cols-4 md:gap-5 bg-[#ececec] p-3 rounded-md md:grid-cols-3 grid-cols-1 shadow-md">
              {/* Sort By */}
              {/* <div>
                <span className="">Sort By</span>
                <select className="py-1 outline-none">
                  <option>Select</option>
                  <option>Non-Vegitarian</option>
                  <option>Vegitarian</option>
                </select>
              </div> */}

              {/* Are you looking for? */}
              <div className="flex flex-col gap-3">
                <span className="">Are you looking for?</span>
                <div className="flex justify-center w-full gap-6">
                  <Radio.Group withAsterisk className="flex">
                    <Group mt="xs">
                      <Radio
                        value="Male"
                        label={<FaMale size={25} />}
                        onChange={(e) => changegender(e.target.value)}
                      />
                      <Radio
                        value="Female"
                        label={<FaFemale size={25} />}
                        onChange={(e) => changegender(e.target.value)}
                      />
                    </Group>
                  </Radio.Group>
                </div>
              </div>

              {/* Age */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="age">
                    <Accordion.Control>Age</Accordion.Control>
                    <Accordion.Panel>
                      <div className="grid grid-cols-3 gap-3 text-center w-full">
                        <Slider min={18} max={70} onChange={(value) => chnageAge(value)} className="py-1 outline-none" />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Country of Residence */}
              <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="country">
                  <Accordion.Control>Country of Residence</Accordion.Control>
                  <Accordion.Panel>
                    <Select
                      label="Country"
                      placeholder={country}
                      onChange={(value) => {
                        handleCountryChange(value);
                        changeCountry(value);
                      }}
                      data={countries}
                      className="py-1 outline-none"
                      searchable
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
              </div>

              {/* Region / District */}
              <div>
              <Accordion chevronPosition="left" variant="filled">
                <Accordion.Item value="Region">
                  <Accordion.Control>Region / District</Accordion.Control>
                  <Accordion.Panel>
                    <Select
                      label="State"
                      placeholder={state}
                      onChange={(value) => {
                        handleStateChange(value);
                        changetate(value);
                      }}
                      data={states}
                      className="py-1 outline-none"
                      searchable
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
              </div>

              {/* Ethnicity */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Ethnicity">
                    <Accordion.Control>Ethnicity</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Sinhalese"
                          value="Sinhalese"
                          onChange={(e) => changegEthencity(e.target.value)}
                        />
                        <Checkbox
                          label="Tamil"
                          value="Tamil"
                          onChange={(e) => changegEthencity(e.target.value)}
                        />
                        <Checkbox
                          label="Muslim"
                          value="Muslim"
                          onChange={(e) => changegEthencity(e.target.value)}
                        />
                        <Checkbox
                          label="Burger"
                          value="Burger"
                          onChange={(e) => changegEthencity(e.target.value)}
                        />
                        <Checkbox
                          label="Other"
                          value="Other"
                          onChange={(e) => changegEthencity(e.target.value)}
                        />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Religion */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Religion">
                    <Accordion.Control>Religion</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Buddihist"
                          value="Buddihist"
                          onChange={(e) => changegReligion(e.target.value)}
                        />
                        <Checkbox
                          label="Christian"
                          value="Christian"
                          onChange={(e) => changegReligion(e.target.value)}
                        />
                        <Checkbox
                          label="Hindu"
                          value="Hindu"
                          onChange={(e) => changegReligion(e.target.value)}
                        />
                        <Checkbox
                          label="Islam"
                          value="Islam"
                          onChange={(e) => changegReligion(e.target.value)}
                        />
                        <Checkbox
                          label="Agnostic"
                          value="Agnostic"
                          onChange={(e) => changegReligion(e.target.value)}
                        />

                        <Checkbox
                          label="Catholic"
                          value="Catholic"
                          onChange={(e) => changegReligion(e.target.value)}
                        />

                        <Checkbox
                          label="None"
                          value="None"
                          onChange={(e) => changegReligion(e.target.value)}
                        />

                        <Checkbox
                          label="Other"
                          value="Other"
                          onChange={(e) => changegReligion(e.target.value)}
                        />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Civil Status */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Civil_Status">
                    <Accordion.Control>Civil Status</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Never Married"
                          value="Never Married"
                          onChange={(e) => changesivilstatus(e.target.value)}
                        />
                        <Checkbox
                          label="Widowed"
                          value="Widowed"
                          onChange={(e) => changesivilstatus(e.target.value)}
                        />
                        <Checkbox
                          label="Divorced"
                          value="Divorced"
                          onChange={(e) => changesivilstatus(e.target.value)}
                        />
                        <Checkbox
                          label="Separated"
                          value="Separated"
                          onChange={(e) => changesivilstatus(e.target.value)}
                        />
                        <Checkbox
                          label="Other"
                          value="Other"
                          onChange={(e) => changesivilstatus(e.target.value)}
                        />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Profession */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Profession">
                    <Accordion.Control>Profession</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Accountant"
                          value="Accountant"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Architect"
                          value="Architect"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Banker"
                          value="Banker"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Business Owner"
                          value="Business Owner"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Chef"
                          value="Chef"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Docotor"
                          value="Docotor"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Driver"
                          value="Driver"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Engineer"
                          value="Engineer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Farmer"
                          value="Farmer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Government Service"
                          value="Government Service"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Graphic Designer"
                          value="Graphic Designer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Hotelier"
                          value="Hotelier"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="IT Professional"
                          value="IT Professional"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Lawyer"
                          value="Lawyer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />

                        <Checkbox
                          label="Lecturer"
                          value="Lecturer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Manager"
                          value="Manager"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Graphic Designer"
                          value="Graphic Designer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Medical Professional"
                          value="Medical Professional"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Miltary"
                          value="Miltary"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Not Employed"
                          value="Not Employed"
                          onChange={(e) => changeProfession(e.target.value)}
                        />

                        <Checkbox
                          label="Nurse"
                          value="Nurse"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Other"
                          value="Other"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Graphic Designer"
                          value="Graphic Designer"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Quantity Surveyor"
                          value="Quantity Surveyor"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Researcher"
                          value="Researcher"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Sales Executive"
                          value="Sales Executive"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Self Employed"
                          value="Self Employed"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Quantity Surveyor"
                          value="Quantity Surveyor"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Surveyor"
                          value="Surveyor"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Teacher"
                          value="Teacher"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                        <Checkbox
                          label="Technician"
                          value="Technician"
                          onChange={(e) => changeProfession(e.target.value)}
                        />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Food Preference */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Food_Preference">
                    <Accordion.Control>Food Preference</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Non Vegetarian"
                          value="Vagitarian"
                          onChange={(e) => changefood_prferance(e.target.value)}
                        />
                        <Checkbox
                          label="Vegetarian"
                          value="Non-vegitarian"
                          onChange={(e) => changefood_prferance(e.target.value)}
                        />
                        <Checkbox label="Vegan" />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Drinking */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Drinking">
                    <Accordion.Control>Drinking</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <Checkbox
                          label="Yes"
                          value="Yes"
                          onChange={(e) => changeDrinking(e.target.value)}
                        />
                        <Checkbox
                          label="No"
                          value="No"
                          onChange={(e) => changeDrinking(e.target.value)}
                        />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Mother Tongue */}

              {/* Education_Level */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Education_Level">
                    <Accordion.Control>Min Education Level</Accordion.Control>
                    <Accordion.Panel>
                      <div className="flex flex-col gap-2">
                        <select
                          className="py-1 outline-none"
                          onChange={(e) => changeeductation(e.target.value)}
                        >
                          <option>Select</option>
                          <option value="Up to GCE O/L">Up to GCE O/L</option>
                          <option value="Up to GCE A/L">Up to GCE A/L</option>
                          <option value="Diploma">Diploma</option>
                          <option value="Professional Qualification">
                            Professional Qualification
                          </option>
                          <option value="Undergraduate">Undergraduate</option>
                          <option value="Post Graduate Diploma">
                            Post Graduate Diploma
                          </option>
                          <option value="Master's Degree or Equivalent">
                            Master's Degree or Equivalent
                          </option>
                          <option value="Phd or Post Doctoral">
                            Phd or Post Doctoral
                          </option>
                        </select>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              {/* Height */}
              <div>
                <Accordion chevronPosition="left" variant="filled">
                  <Accordion.Item value="Height">
                    <Accordion.Control>Height</Accordion.Control>
                    <Accordion.Panel>
                      <div className="grid grid-cols-3 gap-3 text-center">
                        <div className="grid grid-cols-3 gap-3 text-center">
                          <Checkbox
                            labe
                            label="4 ft"
                            value="4"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.1 ft"
                            value="4.1"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.2 ft"
                            value="4.2"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.3 ft"
                            value="4.3"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.4 ft"
                            value="4.4"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.5 ft"
                            value="4.5"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.6 ft"
                            value="4.6"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.7 ft"
                            value="4.7"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.8 ft"
                            value="4.8"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="4.9 ft"
                            value="4.9"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.1ft "
                            value="5.1"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.2 ft"
                            value="5.2"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.3 ft "
                            value="5.3"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.4ft "
                            value="5.4"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.5 ft"
                            value="5.5"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.6 ft "
                            value="5.6"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.7 ft"
                            value="5.7"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.8 ft "
                            value="5.8"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="5.9 ft "
                            value="5.9"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.0 ft"
                            value="6"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.1 ft "
                            value="6.1"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.2 ft"
                            value="6.2"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.3 ft"
                            value="6.3"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.4ft "
                            value="6.4"
                            onChange={(e) => changeHeight(e.target.value)}
                          />

                          <Checkbox
                            label="6.5ft"
                            value="6.5"
                            onChange={(e) => changeHeight(e.target.value)}
                          />

                          <Checkbox
                            label="6.6ft"
                            value="6.6"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.7ft"
                            value="6.7"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.8ft"
                            value="6.8"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="6.9 ft"
                            value="6.9"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                          <Checkbox
                            label="7 ft"
                            value="7"
                            onChange={(e) => changeHeight(e.target.value)}
                          />
                        </div>
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="flex justify-end mt-5 md:mt-0 md:justify-start">
                <Button
                  variant="filled"
                  color="#EACC00"
                  onClick={() => submitlocation()}
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </Drawer>

        <div className="flex flex-col gap-10">
          {/***cards----------------------------------------------- */}
          {add.map((c) => (
            <Card myadd={c} />
          ))}
        </div>

        <div className="flex justify-center">
          {add.length > 20
            ? <button variant="filled" className="bg-white border-2 text-yellow-500 border-yellow-500 p-2 rounded-lg hover:bg-yellow-500 hover:text-white" onClick={() => handleLoadMore()}>
              load more
            </button>
            :
            <button variant="filled" className="bg-white border-2 text-yellow-500 border-yellow-500 p-2 rounded-lg hover:bg-yellow-500 hover:text-white" onClick={() => handleLoadMore()}>
              load more
            </button>
          }

        </div>
      </div>
    </div>
  );
};

export default Home;
