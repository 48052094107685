import { Button, Input } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import ManageBlogCard from "../../../components/cards/ManageBlogCard";
import { Link } from "react-router-dom";
import { showBlogs, deleteBlog } from "../../../functions/blogs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  useEffect(() => {
    loadMore();
  }, []);

  const loadMore = async () => {
    const response = await showBlogs();
    setBlogs(response);
  };
const {user} = useSelector((state) => state.user)
  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastBlog = currentPage * itemsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - itemsPerPage;
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const deleteBlogs = async (blogId) => {
    console.log(blogId)
    const response = await deleteBlog(user.token,blogId);
    loadMore();
    toast.success(response.message);
  };
  return (
    <div className="flex flex-col gap-10">
      <h1 className="text-xl font-medium">Blogs Management</h1>

      <div className="flex items-center justify-between w-full">
        <Input
          placeholder="Search blogs from title"
          rightSection={<IoSearchSharp />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Link to="/admin/blogs_management/add_new">
          <Button variant="filled" color="#EACC00">
            Add New Blog
          </Button>
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-10 md:gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-10">
        {currentBlogs.map((b) => (
          <ManageBlogCard key={b._id} b={b} deleteBlogs={deleteBlogs} />
        ))}
      </div>

      <div className="flex justify-center">
        {filteredBlogs.length > itemsPerPage && (
          <ul className="flex gap-2">
            {Array(Math.ceil(filteredBlogs.length / itemsPerPage))
              .fill()
              .map((_, index) => (
                <li key={index}>
                  <Button onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </Button>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BlogManagement;
