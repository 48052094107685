import { Button } from "@mantine/core";
import React, { useState,useEffect, useCallback } from "react";
import Card from "../cards/Card";
import { Link, useNavigate } from "react-router-dom";
import { addsRemoveUser } from "../../functions/addvertismentt";
import {getPremuimesAll} from "../../functions/premium"
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import { PulseLoader } from "react-spinners";

const MyAdd = ({ myadd }) => {
  const [premuim, setPremum] = useState([]);
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.user);
  const [loading,setLoading] = useState(false)
  const deleteAdvertsiment = async (addId) => {
    const response = await addsRemoveUser(user.token,addId);
    toast.success(response.message);
  };
  const getAllPremuim = useCallback(async () => {
    const response = await getPremuimesAll()
    setPremum(response);
  },[])


  useEffect(() =>{
    getAllPremuim()
  },[getAllPremuim])



  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (window.payhere) {
      window.payhere.onCompleted = function onCompleted(orderId) {
        console.log("Payment completed. OrderID:" + orderId);
        // Note: validate the payment and show success or failure page to the customer
      };

      window.payhere.onDismissed = function onDismissed() {
        // Note: Prompt user to pay again or show an error page
        console.log("Payment dismissed");
      };

      window.payhere.onError = function onError(error) {
        // Note: show an error page
        console.log("Error:" + error);
      };
    }
  }, []);

  const paymentFunctions = async (packgeId) => {

    if(!user.token){
      navigate(`/login/mobile`,{state:{from:`/packages`}});
      return
    }
       try {
        setLoading(true)
          const response = await axios.post(
            `${process.env.REACT_APP_API}/user/payment/payhere`,
            {packgeId:false, premiumId:packgeId},
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          
          if (window.payhere) {
       
            console.log(response.data);
            const payment = {
              merchant_id:response.data.merchant_id,
              return_url: response.data.return_url,
              cancel_url: response.data.cancel_url,
              notify_url: response.data.notify_url,
              order_id: response.data.order_id,
              items: response.data.items,
              amount: response.data.amount,
              currency: response.data.currency,
              hash: response.data.hash , // Replace with generated hash retrieved from backend
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              email: response.data.email,
              phone: response.data.mobile1,
              address: response.data.address,
              city: "Colombo",
              country: "Sri Lanka",
              delivery_address: "",
              delivery_city: "",
              delivery_country: "",
              custom_1: "",
              custom_2: "",
            };
            window.payhere.startPayment(payment);
          }
     
          setLoading(false)
    
        } catch (error) {
          console.log("======>",error)
          toast.error(error?.response?.data?.error?.message);
        }
    
      };

  return (
    <div className="flex flex-col w-full gap-10 py-10">
      <div className="flex flex-col justify-between w-full gap-6 md:flex-row">
        <div className="flex w-full gap-2">
          <span className="font-bold">Advertisement ID :</span>
          <span className="">{myadd._id ? myadd._id.toString().padStart(6, '0').substring(0, 6): null }  </span>
        </div>
        <div className="flex justify-end w-full"></div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-3">
            <span className="font-medium">
              {" "}
              <Button
                variant="filled"
                onClick={() => deleteAdvertsiment(myadd._id)}
                radius={"xl"}
              >
                Delete
              </Button>
            </span>
          </div>

          <div className="flex items-center gap-6">
            {premuim.map((c) => (
              <Button variant="filled" radius={"xl"} color="#EACC00" onClick={() => paymentFunctions(c._id)}>
                {loading ?      <PulseLoader size={7}/> :c.name}
              </Button>
            ))}

            <Link to="/user/advertisement/edit">
              {" "}
              <span className="font-medium underline">Edit Add</span>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <Card myadd={myadd} />
      </div>
    </div>
  );
};

export default MyAdd;
