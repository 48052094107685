import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./App.css";
import { store } from "./app/store";

import "@mantine/core/styles.css";

import { MantineProvider } from "@mantine/core";
import { Provider } from "react-redux";


ReactDOM.createRoot(document.getElementById("root")).render(

    <MantineProvider>
        <Provider store={store}>
      <App />
      </Provider>
    </MantineProvider>

);
