import React from "react";
import Logo from "../../assets/images/thuhina logo6.png";
import Center_img from "../../assets/images/header_center_img.png";
import { Button, Drawer } from "@mantine/core";
import { IoMenu } from "react-icons/io5";
import { useDisclosure } from "@mantine/hooks";
import { Link, useNavigate } from "react-router-dom";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../app/features/userSlice";


const Header = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="flex flex-col w-full pt-3">
      <div className="flex w-full px-3 pb-1 border-b-2 md:px-10 lg:px-16 xl:px-20">
        <div className="flex justify-start w-1/2 md:w-1/3">
          <Link to="/">
            <img src={Logo} alt="/" className="h-20" />
          </Link>
        </div>
        <div className="items-end justify-center hidden w-1/3 md:flex">
          <img src={Center_img} alt="/" className="h-10" />
        </div>
        <div className="items-center justify-end hidden w-1/3 gap-3 lg:flex">
          {!user.token ? (
            <>
              <Link to="/register">
                <span className="text-[#EACC00] hover:text-black hover:cursor-pointer">
                  Register
                </span>
              </Link>
              <Link to="/login/mobile">
                <Button variant="outline" color="#EACC00" radius="xs">
                  Login
                </Button>
              </Link>
            </>
          ) : null}
        </div>


        <div className="flex items-center justify-end w-1/2 gap-3 lg:hidden md:w-1/3">
          {!user.token ? (
            <>
              <div className="flex items-center justify-end w-1/3 gap-3">
                <Link to="/register">
                  <span className="text-[#EACC00] hover:text-black hover:cursor-pointer">
                    Register
                  </span>
                </Link>
                <Link to="/login/mobile">
                  <Button variant="outline" color="#EACC00" radius="xs">
                    Login
                  </Button>
                </Link>
              </div>

            </>
          ) : null}
          <IoMenu size={30} onClick={open} />

        </div>



      </div>

      <div className="justify-between hidden w-full px-3 py-2 border-b-2 lg:flex md:px-10 lg:px-16 xl:px-20">
        <div className="flex items-center justify-start gap-2 text-sm ">
          {/* <span className='hover:text-[#EACC00] hover:cursor-pointer'>English</span>
                                            <span>/</span>
                                            <span className='hover:text-[#EACC00] hover:cursor-pointer'>සිංහල</span>
                                            <span>/</span>
                                            <span className='hover:text-[#EACC00] hover:cursor-pointer'>தமிழ்</span> */}
          {/* <Translate/> */}
        </div>


        <div className="flex items-center justify-center ">
          <ul className="flex gap-10">
            <Link to="/">
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                Home
              </li>
            </Link>
            <Link to="/packages">
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                Packages
              </li>
            </Link>
            <Link to="/about">
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                About Us
              </li>
            </Link>
            <Link to="/contact">
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                Contact Us
              </li>
            </Link>
            <Link to="/magazine">
              
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                
                Magazines
              </li>
            </Link>
            <Link to="/blogs">
              
              <li className="hover:text-[#EACC00] hover:cursor-pointer">
                
                Blogs
              </li>
            </Link>
          </ul>
        </div>


        <div className="flex items-center justify-end gap-3">
          {user.token ? (
            <Select
              style={{ width: 150 }}
              options={[
                {
                  value: "profile",
                  label: <Link to="/user/profile"> My Profile </Link>,
                },
                {
                  value: "advertisement",
                  label: (
                    <Link to="/user/advertisement"> My Advertisement </Link>
                  ),
                },
                {
                  value: "packages",
                  label: <Link to="/user/mypackages"> My Packages </Link>,
                },
                {
                  value: "myrequest",
                  label: <Link to="/user/myrequest">My Request</Link>,
                },
                {
                  value: "logout",
                  label: <li onClick={() => handleLogout()}> Log Out </li>,
                },
              ]}
              defaultValue="My Account"
            />
          ) : null}
        </div>
      </div>

      <Drawer
        opened={opened}
        onClose={close}
        title={<img src={Logo} alt="/" className="h-14" />}
        size="80%"
      >
        <div className="flex flex-col justify-center gap-6 ">
          <div className="flex items-center justify-between w-full gap-3">
            {/* <Link to="/message">
              <BiMessageSquareDetail size={25} />
            </Link> */}
            {user.token ? (
            <Select
              style={{ width: 150 }}
              options={[
                {
                  value: "profile",
                  label: <Link to="/user/profile" onClick={close}> My Profile </Link>,
                },
                {
                  value: "advertisement",
                  label: (
                    <Link to="/user/advertisement" onClick={close}> My Advertisement </Link>
                  ),
                },
                {
                  value: "packages",
                  label: <Link to="/user/mypackages" onClick={close}> My Packages </Link>,
                },
                {
                  value: "myrequest",
                  label: <Link to="/user/myrequest" onClick={close}>My Request</Link>,
                },
                {
                  value: "logout",
                  label: <li onClick={() => handleLogout()}> Log Out </li>,
                },
              ]}
              defaultValue="My Account"
            />
          ) : null}
          </div>
          <hr className="border-b-2 " />
          <div className="flex flex-col items-center justify-center gap-3 ">
            <ul className="flex flex-col items-center gap-5 text-lg">
              <Link to="/" onClick={close}>
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  Home
                </li>
              </Link>
              <Link to="/packages" onClick={close}>
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  Packages
                </li>
              </Link>
              <Link to="/about" onClick={close}>
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  About Us
                </li>
              </Link>
              <Link to="/contact" onClick={close}>
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  Contact Us
                </li>
              </Link>
              <Link to="/magazine" onClick={close}>
                
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  
                  Magazines
                </li>
              </Link>
              <Link to="/blogs" onClick={close}>
                
                <li className="hover:text-[#EACC00] hover:cursor-pointer">
                  
                  Blogs
                </li>
              </Link>
            </ul>
          </div>

          
          <hr className="border-b-2 " />

          <div className="flex items-center justify-center gap-2 text-sm ">
            {/* <span className="hover:text-[#EACC00] hover:cursor-pointer">
              
              English
            </span>
            <span> /</span>
            <span className="hover:text-[#EACC00] hover:cursor-pointer">
              
              සිංහල
            </span>
            <span> /</span>
            <span className="hover:text-[#EACC00] hover:cursor-pointer">
              
              தமிழ்
            </span> */}

          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
