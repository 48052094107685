import React, { useCallback, useEffect, useState } from "react";
import MagazineCard from "../../components/cards/MagazineCard";
import { getMagzines } from "../../functions/magazine";

const Magazine = () => {
  const [magazines, setMagaziens] = useState([]);
  const loadMagazine = useCallback(async () => {
    const response = await getMagzines()
    setMagaziens(response)
  },[])

  useEffect(() =>{
    loadMagazine()
  },[loadMagazine])

  return (
    <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24">
      <div className="w-full bg-[#E8B202] py-4 shadow-xl rounded-md javanese_font">
        <h1 className="text-white  text-3xl font-medium text-center">
          Monthly Magazines
        </h1>
      </div>

      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
      {magazines.map((c) =>(
 <MagazineCard m={c}/>
      ))} 
     
      </div>
    </div>
  );
};

export default Magazine;
