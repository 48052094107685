import { useEffect, useState } from "react";
import { useNavigate , useLocation} from "react-router-dom";
import Logo from "../../assets/images/thuhina logo6.png";
import Typewriter from 'typewriter-effect';
export default function LoadingToRedirect({path ="/"}){
    //state
    const [count, setCount] =  useState(10);
    //hooks
    const navigate = useNavigate();
    const location  = useLocation();

    useEffect(() =>{
        const interval = setInterval(() =>{
            setCount((currentCount) => --currentCount);
        },1000);
        // redirect once count is equal to 0
        count === 0 &&  navigate(`${path}`,{
            state : location.pathname,
        });

        //cleanup
        return() => clearInterval(interval);

    });

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="mt-4 text-center">
            <img src={Logo} alt="Logo" />
            <h1 className="text-transparent text-3xl bg-clip-text bg-gradient-to-r from-blue-400 to-pink-600">
                <Typewriter
                    options={{
                        strings: [" t h u h i n a . l k"],
                        autoStart: true,
                        loop: false,
                    }}
                />
            </h1>
        </div>
    </div>
    );
}
