import React, { useEffect, useState } from "react";
import Card_img from "../../assets/images/blogs_card.png";
import { useParams } from "react-router-dom";
import { showOneBlog } from "../../functions/blogs";
import moment from "moment";

const BlogsSingle = () => {
  const [add, setAdd] = useState({});
  const { id } = useParams();
  useEffect(() => {
    loadmore();
  });

  const formattedCreatedAt = moment(add.createdAt).fromNow()
  const loadmore = async () => {
    const response = await showOneBlog(id);
    setAdd(response);
  };
  return (
    <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24">
      <div className="w-full bg-[#E8B202] py-4 shadow-xl rounded-md javanese_font">
        <h1 className="text-white  text-2xl font-bold text-center ">Blog</h1>
      </div>

      <div className="flex flex-col w-full gap-5">
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-bold">
            {add.title}
          </h1>
          <span className="text-sm">{formattedCreatedAt}</span>
        </div>
        <div className="flex flex-col w-full gap-5 ">
          <div className="flex justify-center h-full w-full">
            {add.images && add.images.length > 0 ? (
              add.images.map((image, index) => (
                <img key={index} src={image.imageUrl} alt={`blog_image_${index}`} className="" />
              ))
            ) : (
              <img src={Card_img} alt="/" className="" />
            )}
          </div>

          <hr />
          <div dangerouslySetInnerHTML={{ __html: add.description }} />
        </div>

      </div>
    </div>
  );
};

export default BlogsSingle;
