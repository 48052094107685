import { Button, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { Badge, Avatar } from "antd";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createBlog } from "../../../functions/blogs";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const AddNewBlog = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState("");

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"], // remove formatting button
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "color",
    "background",
    "list",
    "bullet",
    "link",
    "image",
    "clean",
  ];

  const handleCloudinaryUpload = async (e, index) => {
    const files = e.target.files;

    const base64Images = await Promise.all(
      Array.from(files).map((file) => convertBase64(file))
    );

    try {
      setLoading(true);
      const responses = await Promise.all(
        base64Images.map((base64) =>
          axios.post(`${process.env.REACT_APP_API}/user/uploadimag`, {
            image: base64,
          })
        )
      );
      setLoading(false);

      const newVariants = [];
      responses.forEach((response, i) => {
        newVariants.push({
          imageUrl: response.data.url,
          publicId: response.data.public_id,
        });
      });
      setImages(newVariants);
    } catch (error) {
      console.log("Error uploading image to Cloudinary:", error);
    }
  };

  const removeCloudinaryUpload = async (public_id) => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API}/user/removeImg`, {
        public_id,
      });
      setLoading(false);

      const newVariants = images.filter(
        (image) => image.publicId !== public_id
      );
      setImages(newVariants);
    } catch (error) {
      console.log("Error removing image from Cloudinary:", error);
    }
  };
  const { user } = useSelector((state) => state.user);

  const submit = async () => {
    const response = await createBlog(user.token, title, description, images);

    toast.success(response.message);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
    
  };

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-xl font-medium">Blogs Management</h1>
      <span>Add New blog</span>
      <div className="flex flex-col w-full gap-5 lg:w-2/3">
        <TextInput
          label="Blog Title"
          placeholder="Eg: Best Weddings"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <ReactQuill
          theme="snow"
          value={description}
          onChange={handleDescriptionChange}
          modules={modules}
          formats={formats}
          placeholder="Enter your Description"
        />

        <div className="flex flex-col gap-2">
          <span className="font-medium">Upload Blog Image</span>
          {images.map((image) => (
            <Badge
              count="x"
              style={{ cursor: "pointer" }}
              className="mb-3"
              onClick={() => removeCloudinaryUpload(image.publicId)}
            >
              <Avatar
                key={image.publicId}
                src={image.imageUrl}
                size={350}
                shape="square"
              />
            </Badge>
          ))}
        </div>

        <input
          type="file"
          name="slip"
          id="slip"
          className="hidden"
          onChange={(e) => handleCloudinaryUpload(e)}
        />

        <label
          htmlFor="slip"
          className="py-[10px] px-[40px] bg-[#f05206] hover:bg-[#f03d06] w-fit text-white rounded-md cursor-pointer "
        >
          <span>{loading ? <PulseLoader size={6} /> : "Upload Image"}</span>
        </label>
        <div className="flex justify-end">
          <Button onClick={() => submit()} color="#EACC00">
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNewBlog;
