import { Button } from '@mantine/core'
import React from 'react'
import MyPhoto from '../..//assets/images/user.png'
import { Link } from 'react-router-dom'
import moment from 'moment';
// Assuming submit is passed as a prop to PendingAdCard
const PendingAdCard = ({ add, submit }) => {
  const today = moment();
  const birthday = moment(add.bod);
  const years = today.diff(birthday, 'years');

  return (
    <div className={`flex w-full ${add.prumeiunm === 'Normel' ? null : 'bg-[#d9b967] '} p-3 rounded-md flex-col lg:flex-row gap-3 lg:gap-0`}>
      <div className='flex w-full lg:w-2/5'>
        <div className='flex items-center justify-center w-1/3'>
          <img src={MyPhoto} alt='' className='w-20 h-20 rounded-full md:h-24 md:w-24' />
        </div>
        <div className='flex flex-col justify-center w-2/3'>
          <h1 className='font-bold'>{add.first_name} {add.last_name}</h1>
          <span className='text-sm'>{years} Years Old</span>
          <div className='flex items-center gap-2'>
            <span className='text-sm'>User Mobile :</span>
            <span className='text-sm font-medium'>0{add?.user?.mobile}</span>
          </div>
          <div className='flex items-center gap-2'>
            <span className='text-sm'>Addtype :</span>
            <span className='text-sm font-medium'>{add.prumeiunm}</span>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-end w-full gap-3 lg:w-3/5'>
        {add.adrole === 'Ready' ? null : (
          <Button variant='filled' color='green' onClick={() => submit(add._id, 'Ready')}>Accept</Button>
        )}
        {add.adrole === 'Decline' ? (


            <Button variant='filled' color='green' onClick={() => submit(add._id, 'Not Ready')}>Not Ready</Button>
       
     
        ) :     <Button variant='filled' color='red'  onClick={() => submit(add._id, 'Decline')}>Decline</Button>}
    
        <Link to={`/addvertisment/view/admin/${add._id}`}>
          <Button variant='filled' color='black' >View Ad</Button>
        </Link>
      </div>
    </div>
  );
};

export default PendingAdCard;