import React from 'react'
import About_img1 from '../assets/images/about_img1.png'

const About = () => {
    return (
        <div className='flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24'>
            <div className='w-full bg-[#E8B202] py-4 shadow-xl rounded-md javanese_font '>
                <h1 className='text-white  text-3xl font-bold text-center'>About Us</h1>
            </div>

            <div className='flex flex-col gap-5 md:gap-10 md:flex-row'>
                <div className='w-full md:w-1/4 md:hidden'>
                    <img src={About_img1} alt='/' width={'100%'}/>
                </div>
                <div className='w-full md:w-3/4'>
                    <p className='text-[#696969] text-justify text-lg'>ආදරනීය හදවත් රාශියක අතැගිලි එක් කල අපි පස් වසරක සේවා අත්දැකීම් තුලින් ඔබට සිතැගි සහකරු හෝ සහකාරිය ඉක්මනින්ම සොයා ගැනිමට "තුහින.lk"අපි කැපවෙන්නෙමු.ඔබ තුහින.lk වෙත පිය නැගීමෙන් ඔබගෙ විවහා අපේක්ෂාවන් ඉතා ඉක්මනින් ආරක්ෂාකාරීව ඉටු කර දිමට තුහින.lk කාර්‍ය්ලමංඩලය වන අපි ඔබ සමග අත්වැල් බැද ගන්නෙමු.</p>
                </div>
                <div className='hidden w-full md:w-1/4 md:flex'>
                    <img src={About_img1} alt='/' width={'100%'}/>
                </div>
            </div>
            <div className='flex flex-col gap-5 md:gap-10 md:flex-row'>
                <div className='w-full md:w-1/4'>
                    <img src={About_img1} alt='/' width={'100%'}/>
                </div>
                <div className='w-full md:w-3/4'>
                    <p className='text-[#696969] text-justify text-lg'>With the hands of many loving hearts, we at "Thuhina.lk" are dedicated to finding your ideal spouse as soon as possible through our five years of service experience. By stepping on Thuhina.lk, we, the Thuhina.lk office, can fulfill your marriage expectations very quickly and safely. We will join hands with you.</p>
                </div>
                 
            </div>
        </div>
    )
}

export default About
