import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GuestLayout from "./components/layouts/GuestLayout";
import Home from "./pages/Home";
import Register from "./pages/auth/Register";
import MyProfile from "./pages/user/MyProfile";
import Advertisement from "./pages/advertisement/Advertisement";
import AddAdvertisement from "./pages/advertisement/AddAdvertisement";
import SingleView from "./pages/Single_view";
import EditAdvertisement from "./pages/advertisement/EditAdvertisement";
import Contact from "./pages/Contact";
import Packages from "./pages/Packages";
import Blogs from "./pages/blog/Blogs";
import BlogsSingle from "./pages/blog/BlogsSingle";
import Magazine from "./pages/magazine/Magazine";
import Message from "./pages/user/Message";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Privacy2 from "./pages/Privacy2";
import Terms from "./pages/Terms";
import Terms2 from "./pages/Terms2";
import AdminLayout from "./components/layouts/AdminLayout";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import UserManagement from "./pages/admin/user/UserManagement";
import AdManagement from "./pages/admin/ads/AdManagement";
import PackagesManagement from "./pages/admin/packages/PackagesManagement";
import AddNewPackage from "./pages/admin/packages/AddNewPackage";
import DeletePackage from "./pages/admin/packages/DeletePackage";
import PaymentHistory from "./pages/admin/payment/PaymentHistory";
import BlogManagement from "./pages/admin/blogs/BlogManagement";
import AddNewBlog from "./pages/admin/blogs/AddNewBlog";
import UpdateBlog from "./pages/admin/blogs/UpdateBlog";
import MagazineManagement from "./pages/admin/magazine/MagazineManagement";
import AddNewMagazine from "./pages/admin/magazine/AddNewMagazine";
import UpdateMagazine from "./pages/admin/magazine/UpdateMagazine";
import AdminMessage from "./pages/admin/message/Message";
import TopAds from "./pages/admin/topAd/TopAds";
import { Toaster } from "react-hot-toast";
import LoginWithMobile from "./components/login/LoginWithMobile";
import LoginWithEmail from "./components/login/LoginWithEmail";
import LoginWithUsername from "./components/login/LoginWithUsername";
import Singleview2 from "./pages/Single_view2";
import Singleview3 from "./pages/user/Single_view3";
import MyPackages from "./pages/user/MyPackages";
import { AdminRoute } from "./components/routes/AdminRoutes";
import UserRoute from "./components/routes/UserRoute";
import Success from "./pages/Success";

import MyRequest from "./pages/MyRequest";
import AcccessContact from "./pages/Usercontact";

import Loading from "./components/Loading";

import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          
          <Route path="/login/mobile" element={<LoginWithMobile />} />
          <Route path="/login/email" element={<LoginWithEmail />} />
          <Route path="/login/username" element={<LoginWithUsername />} />
          <Route path="/register" element={<Register />} />

          <Route path="" element={<GuestLayout />}>
            <Route path="/" element={<Home />} />
            
            {/* loading */}
            <Route path="/loading" element={<Loading />} />

            

            <Route path="/user" element={<UserRoute />}>
              <Route path="myrequest" element={<MyRequest />} />
              <Route path="profile" element={<MyProfile />} />
              <Route
                path="/user/contact/add/:id"
                element={<AcccessContact />}
              />
              <Route path="advertisement" element={<Advertisement />} />
              <Route
                path="advertisement/add_new"
                element={<AddAdvertisement />}
              />
              <Route path="my/add" element={<Singleview3 />} />
              <Route
                path="advertisement/edit"
                element={<EditAdvertisement />}
              />
              <Route path="mypackages" element={<MyPackages />} />
            </Route>

            <Route path="addvertisment/:id" element={<SingleView />} />
            <Route
              path="addvertisment/view/admin/:addId"
              element={<Singleview2 />}
            />

            <Route path="/contact" element={<Contact />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/single/:id" element={<BlogsSingle />} />
            <Route path="/magazine" element={<Magazine />} />
            <Route path="/message" element={<Message />} />
            <Route path="/payment-success" element={<Success />} />
            

            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/sinhala-privacy" element={<Privacy2 />} />
            <Route path="/terms&conditions" element={<Terms />} />
            <Route path="/sinhala-terms&conditions" element={<Terms2 />} />
          </Route>
          <Route path="/admin" element={<AdminRoute />}>
            <Route element={<AdminLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="user_management" element={<UserManagement />} />
              <Route path="ad_management" element={<AdManagement />} />
              <Route path="topads" element={<TopAds />} />
              <Route
                path="package_management"
                element={<PackagesManagement />}
              />
              <Route
                path="package_management/add_new"
                element={<AddNewPackage />}
              />
              <Route
                path="package_management/delete"
                element={<DeletePackage />}
              />
              <Route path="payment_history" element={<PaymentHistory />} />
              <Route path="blogs_management" element={<BlogManagement />} />
              <Route path="blogs_management/add_new" element={<AddNewBlog />} />
              <Route path="blogs_management/update" element={<UpdateBlog />} />
              <Route
                path="Magazines_management"
                element={<MagazineManagement />}
              />
              <Route
                path="Magazines_management/add_new"
                element={<AddNewMagazine />}
              />
              <Route
                path="Magazines_management/update"
                element={<UpdateMagazine />}
              />
              <Route path="messages" element={<AdminMessage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
