import axios from "axios";
import toast from "react-hot-toast";

export const createContact = async (token,reciverId,addId) => {

      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/partner`,
        {reciverId,addId},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
 
  };
  

export const getPartners = async (token,access) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/my/partner`,
      {access},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};


export const accessSetSender = async (token,accessId,access) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/user/partenr/add`,
      {accessId,access},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const getaccessContact = async (token,accessId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/partenr/access`,
      {accessId},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};



export const ContactUs = async (first_name,last_Name,email,message,mobile) => {

  const response = await axios.post(
    `${process.env.REACT_APP_API}/auth/contact/us`,
    {first_name,last_Name,email,message,mobile},
 
    
  );

  return response.data;

};
