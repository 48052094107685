import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <div className="flex flex-col w-full gap-10 pb-10">
        <div className="flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font">
          <h1 className="text-3xl text-white "> Terms & Conditions</h1>
          <span className="text-lg text-white">
            Lastly Updated on 3 / 4 / 2024
          </span>
        </div>

        <div className="flex flex-col gap-10 px-3 md:px-10 lg:px-16 xl:px-24">
          <div className="flex flex-col gap-3">
            <h1 className="text-lg font-medium">
              Welcome to thuhina.lk website and thuhina mangala sewaya private
              limited providing you thuhina wedding services.
            </h1>
            <p>
              thuhina.lk website has offered to choose the best marriage plan
              that suits you through Thuhina Mangal Sewaya, which claims many
              successful weddings.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              1) All the information on the thuhina.lk website is information
              submitted by the account members, so the organization is not
              responsible for the accuracy of that information.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            {/* <h1 className='text-lg font-bold'>Our Terms & COndtions statement explains the following:</h1> */}
            <p className="text-[#696969]">
              2) You must be over 18 years of age to use thuhina.lk website.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              3) The registered member must remove the phone number inactive
              accounts and successful accounts and the organization will not
              accept the responsibility if any member is inconvenienced by not
              doing so. Someone is aware of the organization regarding such an
              account After doing so, the agency will check its information and
              take steps to remove it.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              4) "Thuhina Mangala Sewaya Pvt. Company" has the right to take legal
              action in connection with the copying of personal information and
              images on our website in any way.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              5) We are bound to provide the information of our members only on
              the basis of a legal requirement and our company is not bound to
              provide that information to other external parties.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              6) Someone using false information, photos, phone numbers Thuhina
              Mangala Sewaya Pvt. Ltd. has the full right to remove the
              accounts that are started without prior notice. The person who
              created the account should take full responsibility for the damage
              caused to the real owners of such information due to the accounts
              with such false information and our company will be freed from
              that responsibility. I hereby inform that.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              7) As this website is an organization that only provides wedding
              planning A person should not create an account on our website,
              behave in a way that is harmful to other members, make improper
              plans, improper influence, and try to induce financial
              transactions. If our organization receives any complaint to do so,
              the organization has the full right to remove the account.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              8) Thuhina Mangala Sewaya Pvt. Ltd. is under no obligation to
              refund the service fee paid by a person to publish an
              advertisement in thuhina.lk.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              9) "Thuhina Mangala Sewaya Pvt. Ltd." has full power to change the
              terms and conditions and add new conditions and it is the
              responsibility of the user to check it.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              10) You want to know about the company "Thuhina Mangala Sewaya
              Private Limited," which was incorporated under the Companies Act
              No. 17 of 1995, and specifically about the provision in the
              articles of association regarding the qualification of the
              directors to attend meetings.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              11) Any person (he or she) who obtains membership in thuhina.lk
              enters into a binding agreement with Thuhina
              Mangala Sewaya Pvt. Ltd.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <Link to="/sinhala-terms&conditions" className="underline">
              Translate to sinhala
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
