import { Button } from "@mantine/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

const PopularPackageCard = ({ p }) => {
  const { user } = useSelector((state) => state.user);
  const [loading,setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    if (window.payhere) {
      window.payhere.onCompleted = function onCompleted(orderId) {
        console.log("Payment completed. OrderID:" + orderId);
        // Note: validate the payment and show success or failure page to the customer
      };

      window.payhere.onDismissed = function onDismissed() {
        // Note: Prompt user to pay again or show an error page
        console.log("Payment dismissed");
      };

      window.payhere.onError = function onError(error) {
        // Note: show an error page
        console.log("Error:" + error);
      };
    }
  }, []);
  
  const paymentFunctions = async (packgeId) => {

if(!user.token){
  navigate(`/login/mobile`,{state:{from:`/packages`}});
  return
}
   try {
    setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API}/user/payment/payhere`,
        { packgeId:packgeId},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
      if (window.payhere) {
   
        console.log(response.data);
        const payment = {
          merchant_id:response.data.merchant_id,
          return_url: response.data.return_url,
          cancel_url: response.data.cancel_url,
          notify_url: response.data.notify_url,
          order_id: response.data.order_id,
          items: response.data.items,
          amount: response.data.amount,
          currency: response.data.currency,
          hash: response.data.hash , // Replace with generated hash retrieved from backend
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          phone: response.data.mobile1,
          address: response.data.address,
          city: "Colombo",
          country: "Sri Lanka",
          delivery_address: "",
          delivery_city: "",
          delivery_country: "",
          custom_1: "",
          custom_2: "",
        };
        window.payhere.startPayment(payment);
      }
 
      setLoading(false)

    } catch (error) {
      console.log("======>",error)
      toast.error(error?.response?.data?.error?.message);
    }

  };
console.log(p._id)

  return (
    
    <div className={`if()bg-[#A9DFBF] flex flex-col justify-between w-full min-h-[200px] md:min-h-[300px] h-auto gap-3 p-5 text-center duration-300 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-xl hover:scale-105`}>
      <span className="text-lg">
        {p.name}
      </span>
      <span className="text-lg">
        {p.days / 30} Month
      </span>
      <div className="flex flex-col gap-1">
        <h1 className="text-2xl font-medium">Rs.{p.price}</h1>
      </div>
      <div className="flex flex-col px-6 text-start">
        <div className="flex justify-center gap-3">
          <span className="text-center">{p.desciription}</span>
        </div>
      </div>
      <div className="w-full">{
        loading ?  <Button
        variant="outline"
        color="black"
 
      >
        <PulseLoader size={7}/>
      </Button> : 
        <Button
        variant="outline"
        color="black"
        onClick={() => paymentFunctions(p._id)}
      >
        Buy
      </Button>
      }
       
      </div>
    </div>
  );
};

export default PopularPackageCard;
