import axios from "axios";
import toast from "react-hot-toast";

export const histroyAllPayments = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/admin/all/payment/histroy`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };
  
export const getMyPayments = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/user/my/payments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};