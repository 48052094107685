import React, { useCallback, useEffect, useState } from "react";
import Bg_top from "../../assets/images/bg_image_top.png";
import Bg_bottom from "../../assets/images/bg_image_bottom.png";
import { getMyPackges } from "../../functions/addPakckge";
import { useSelector } from "react-redux";
import moment from "moment/moment";
const MyPackages = () => {
  const [packages, setPackages] = useState({});


  const { user } = useSelector((state) => state.user);

  const loadMyPackges = useCallback(async () => {
    const response = await getMyPackges(user.token);
    setPackages(response);
  },[user.token]);
    useEffect(() => {
    loadMyPackges();
  },[loadMyPackges]);
//sdefd
  return (
    <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24">
      <div className="w-full bg-[#E8B202] py-4 shadow-xl rounded-md">
        <h1 className="text-white  text-2xl font-medium text-center">
          My Packages
        </h1>
      </div>

      <div>
     
            {packages ? (
              <div className="p-3">
                <div className="flex justify-center w-full">
                  <div className="rounded-lg shadow-lg  flex bg-[#F9F9F9] md:relative justify-between lg:w-4/5 w-full">
                    <img src={Bg_top} alt="/" className="hidden md:flex" />
                    <img src={Bg_bottom} alt="/" className="hidden md:flex" />
                    <div className="top-0 left-0 flex justify-center w-full h-full gap-10 p-10 md:absolute ">
                      <div className="flex flex-col justify-between w-72 gap-3 p-5 text-center duration-300 bg-transparent shadow-xl rounded-xl hover:scale-105">
                        <span className="text-lg">
                          {packages?.package?.name } 
                        </span>
                        <div className="flex flex-col gap-1">
                          <h1 className="text-2xl font-medium">
                            Rs. {packages?.package?.price}
                          </h1>
                         
                        </div>
                        <div className="w-full">
                          <span className="font-medium">expdate {moment(packages.expDate).format('L')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              "Not yet added Pakges"
            )}
    
      </div>
    </div>
  );
};

export default MyPackages;
