import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { Button } from "@mantine/core";
import { accessSetSender, getPartners } from "../functions/contact";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import toast from "react-hot-toast";

function MyRequest() {
  const [selectedValue, setSelectedValue] = useState("importrequests");
  const [sendrequest, setSendRequest] = useState([]);
  const [reciverrequest, setReciverRequest] = useState([]);
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  const { user } = useSelector((state) => state.user);
  const loadSendRequest = useCallback(async () => {
    const response = await getPartners(user.token, true);
    setSendRequest(response);
  }, [user.token]);

  const loadReceverRequest = useCallback(async () => {
    const response = await getPartners(user.token, false);
    setReciverRequest(response);
  }, [user.token]);
  useEffect(() => {
    loadSendRequest();
    loadReceverRequest();
  }, [loadSendRequest, loadReceverRequest]);

  console.log(sendrequest);

  const submit = async (accessId,access) =>{
    const response = await accessSetSender(user.token,accessId,access)
    toast.success(response.message);

    loadReceverRequest()
     
  }
  return (
    <>
      <div className="w-[98%] ml-[1%] md:w-[80%] md:ml-[10%] mt-5">
        <div className="flex items-center justify-end gap-3">
          <Select
            style={{ width: 150 }}
            options={[
              {
                value: "importrequests",
                label: <Link to=""> Send Requests</Link>,
              },

              {
                value: "acceptrequests",
                label: <Link to=""> Reciver Requests</Link>,
              },
            ]}
            defaultValue="importrequests"
            onChange={handleChange}
          />
        </div>

        <div
          className="w-full mt-3"
          id="importrequests"
          style={{
            display: selectedValue === "importrequests" ? "block" : "none",
          }}
        >
          {/* Import Requests */}
          <div class="flex flex-col">
            <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-0">
                <div class="overflow-hidden">
                  <table class="min-w-full">
                    <thead class="bg-[#E8B202] border-b">
                      <tr>
                        <th
                          scope="col"
                          class="text-sm  text-white font-medium px-6 py-2 text-center"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        >
                          Access
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        >
                          send date
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sendrequest.map((c) => (
                        <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                          <td class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap text-center">
                            {c?.receiver?.add?.first_name}{" "}
                            {c?.receiver?.add?.first_name}
                          </td>

                          {c?.receiver?.access ? (
                            <td class="text-sm text-gray-900 font-medium  py-2 whitespace-nowrap text-center">
                              <div className="flex justify-center items-center">
                                <Link to={`/user/contact/add/${c._id}`}>
                               
                                <Button variant="filled" color="#EACC00"> See Profile</Button>
                                </Link>
                              </div>
                            </td>
                          ) : (
                            <td class="text-sm text-gray-900 font-medium  py-2 whitespace-nowrap text-center">
                              <div className="flex justify-center items-center">
                                Not yet access Contact
                              </div>
                            </td>
                          )}
                          <td class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap text-center">
                            {moment(c.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
   
        <div
          className="w-full  mt-3"
          id="acceptrequests"
          style={{
            display: selectedValue === "acceptrequests" ? "block" : "none",
          }}
        >
          {/* Accept Requests */}
          <div class="flex flex-col">
            <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-0">
                <div class="overflow-hidden">
                <table class="min-w-full">
                    <thead class="bg-[#E8B202] border-b">
                      <tr>
                        <th
                          scope="col"
                          class="text-sm  text-white font-medium px-6 py-2 text-center"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        >
                          Access
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        >
                          send date
                        </th>
                        <th
                          scope="col"
                          class="text-sm  font-medium text-white px-6 py-2 text-center"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {reciverrequest.map((c) => (
                        <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                          <td class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap text-center">
                            {c?.sender?.user?.first_name}{" "}
                            {c?.sender?.user?.first_name}
                          </td>

                          {c?.receiver?.access ?("you All raedy give contact access") : 
                    (
                      <td class="text-sm text-gray-900 font-medium  py-2 whitespace-nowrap text-center">
                       <Button variant="filled" color="#EACC00" onClick={() => submit(c._id,true)}> Accept</Button>
                      </td>
                    )
                          }
                          <td class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap text-center">
                            {moment(c.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyRequest;
