import React from "react";
import { Link } from "react-router-dom";

const Terms2 = () => {
  return (
    <>
      <div className="flex flex-col w-full gap-10 pb-10">
        <div className="flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font">
          <h1 className="text-3xl text-white "> Terms & Conditions</h1>
          <span className="text-lg text-white">
            Lastly Updated on 3 / 4 / 2024
          </span>
        </div>

        <div className="flex flex-col gap-10 px-3 md:px-10 lg:px-16 xl:px-24">
          <div className="flex flex-col gap-3">
            <h1 className="text-lg font-bold">
              thuhina.lk වෙබ් අඩවිය සහ තුහින මංගල සේවය ඔබට සපයන thuhina mangala
              sewaya private limited වෙත ඔබව සාදරයෙන් පිළි ගනිමු.
            </h1>
            <p>
              සාර්ථක විවහා මංගල්‍ය රාශියකට හිමිකම් කියන තුහින මංගල සේවය මගින්
              ඔබට ගැලපෙන හොදම විවහා යොජනා තොරා ගැනිමට thuhina.lk වෙබ් අඩවිය
              ඉදිරිපත් කර ඇත..
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              1) thuhina.lk වෙබ් අඩවියෙහි ඇති සියලුම තොරතුරු ගිණුම් සැදු
              සමාජිකයින් විසින් ඉදිරිපත් කර ඇති තොරතුරු බැවින් එම තොරතුරු වල
              නිවැරද්‍යතාවය සම්බන්ධයෙන් ආයතනය වග කියනු නොලැබේ.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            {/* <h1 className='text-lg font-bold'>Our Terms & COndtions statement explains the following:</h1> */}
            <p className="text-[#696969]">
              2) thuhina.lk වෙබ් අඩවියෙ සේවය ලබා ගැනිමට ඔබ අනිවාර්යයෙන්ම වයස
              අවුරුදු 18ට වඩා වැඩි විය යුතුයි.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              3) දුරකථන අංක අක්‍රීය ගිණුම් සහ යොජනා සාර්ථක වූ ගිණුම් ඉවත් කර
              ගැනිම ලියාපදිංචි වු සමාජිකයා විසින් සිදු කල යුතු අතර එසෙ
              නොකීරිමෙන් යම් සමාජිකයෙක් අපහසුතාවට පත් වුවහොත් එහි වගකීම ආයතනය
              භාර ගනු නොලැබේ . එවැනි ගිණුමක් සම්බන්ධයෙන් යම් අයෙක් ආයතනය දැනුවත්
              කල පසු එහි තොරතුරු පරීක්ෂා කර එය ඉවත් කිරිමට ආයතනය පියවර ගනු ඇත.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              4) අප වෙබ් අඩවියෙහි ඇති පුද්ගල තොරතුරු සහ චායා රුප කිසිදු අයුරකින්
              පිටපත් කර ගැනිමට සම්බන්ධව නිතිමය පියවර ගැනිමට "තුහින මංගල සේවය
              පුද්ගලික සමාගම" යන ආයතනයට බලය ඇත.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              5) අපගෙ සාමාජිකයන්ගේ තොරතුරු නිතියේ අවශ්‍යතාවයක් මත පමණක් ලබා දිමට
              අපි බැදි සිටින අතර එම තොරතුරු වෙනත් භාහිර පාර්ශයන් සදහා ලබා දිම අප
              ආයතනය බැදි නොමැත
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              6) යම් අයෙකු අසත්‍ය තොරතුරු , චායා රුප, දුරකථන අංක යොදා ගනිමින්
              ආරම්භ කරන ගිණුම් පෙර දැනුම් දිමකින් තොරව ඉවත් කිරිමට තුහින මංගල
              සේවය පුද්ගලික සමාගමට සම්පුර්ණ අයිතිය ඇත.එවැනි අසත්‍ය තොරතුරු ඇති
              ගිණුම් හෙතුකොට ගෙන එම තොරතුරු වල සැබැ අයිතිකාරුවන්ට සිදු වන හානිය
              සම්බන්ධයෙන් එම ගිණුම නිර්මාණය කල පුද්ගලයා සම්පුර්ණ වගකිම භාර ගත
              යුතු අතර අප ආයතනය එ වගකීමෙන් මිදෙන බව මෙයින් දැනුම් දෙමි.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              7) මෙම වෙබ් අඩවිය විවහා යොජනා පමණක් සපයන ආයතනයක් බැවින් යම්
              පුද්ගලයකු අපගෙ වෙබ් අඩවිය තුල ගිණුමක් සදා අනෙකුත් සමාජිකයන්ට
              හානිවන අයුරින් හැසිරිම, අනිසි යොජනා කිරිම, අනිසි බලපැම් කිරිම,
              මුල්‍යමය ගනුදෙනු වලට පෙලබීමට උත්සාහ කිරිම නොකල යුතු වේ. එසේ කිරිමට
              යම් පැමිනිල්ලක් අප ආයතනයට ලැබුන හොත් එම ගිණුම ඉවත් කිරිමට සම්පුර්ණ
              අයිතිය ආයතනය සතු වේ
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              8) යම් පුද්ගලයකු thuhina.lk තුල දැන්විමක් පල කිරිමට ගෙවන ලද සේවා
              ගාස්තුව නැවත ගෙවිමට තුහින මංගල සේවය පුද්ගලික සමාගම කිසිදු
              අයුරිකින් බැදි නොමැත.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              9) මෙහි ඇති නියමයන් සහ කොන්දේසි වෙනස් කිරිමට , නව කොන්දේසි එකතු
              කිරිමට සම්පුර්ණ බලය "තුහින මංගල සේවය පුද්ගලික සමාගම" වෙත බලය ඇති
              අතර එය පරික්ෂා කර බැලිම පරිශිලකයාගෙ වගකිම වේ.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              10) අප ආයතනය සම්බන්ධව ගැටලු නිරවුල් කර ගැනිමට ( thuhina.lk /
              thuhina mangala sewaya private limited ) සම්බන්ධයෙන් වර්ෂ 1995 අංක
              11 ආරවුල් බෙරුම් කරිමේ පනත යටතේ බේරුම්කරුවන් තුන්දෙනෙක්ගෙන් සැදුම්
              ලත් සභාවක් ඉදිරියේ කැකිරාවේදී විභාගයට ගැනිමට ඔබ හා thuhina mangala sewaya
              private limited බැදි සිටි.
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#696969]">
              11)thuhina.lk තුල සමාජිකත්වය ලබා ගන්නා ඔනැම පුද්ගලයකු ( ඔහු හෝ ඇය)
              තුහින මංගල සේවය පුද්ගලික සමාගම සමග ඉහත සදහන් කරුණු යටතේ
              නිතියානුකුල ගිවිසුමට එලැබෙන අතර එය නිතිගත වු බවට සැලකේ.
            </p>
          </div>
          <div className="flex flex-col gap-3">
          <Link to="/terms&conditions" className='underline'>Translate to English</Link>
          </div>

        </div>
      </div>
    </>
  );
};

export default Terms2;
