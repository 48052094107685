import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAdminAdvertsimentById } from "../functions/addvertismentt";
import { Country, State } from "country-state-city";
import { Button } from "@mantine/core";
import { createContact } from "../functions/contact";
import toast from "react-hot-toast";
import { FcLike } from "react-icons/fc";

const Singleview2 = () => {

  const [add, setAdd] = useState({});
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('')

  const { user } = useSelector((state) => state.user);
  const { addId } = useParams();
  useEffect(() => {
    const fetchAdvertisements = async () => {
      setLoading(true);
      const response = await getAdminAdvertsimentById(user.token, addId);
      setAdd(response); // Assuming the response is an array
      getContry(response.residency?.country);
      getState(response?.residency?.state, response.residency?.country)
      setLoading(false);
    };
    fetchAdvertisements();
  }, [user.token, addId]);

  const getContry = (countryCode) => {
    const selectedCountry = Country.getCountryByCode(countryCode);
    setCountry(selectedCountry.name)
  }

  const getState = (stateCode, countryCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, countryCode)
    setState(state.name)
  }

  const submit =async () =>{
const response = await createContact(user.token,add.user,add._id);
toast.success(response.message)
  }
  return (
    <>
      <div className="flex flex-col gap-10 px-3 pt-10 md:px-10 lg:px-16 xl:px-24">
        <div className="w-full bg-[#E8B202] py-5 shadow-xl rounded-md">
          <h1 className="text-white  text-2xl font-medium text-center">
            {loading ? null : `View ${add.user?.first_name}`}
          </h1>
        </div>
      </div>

      <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24 bg-[#F9F9F9]">
        <div className="flex flex-col justify-center w-full gap-10">
          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Basic
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Display Name</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">
                    {add.first_name} {add.last_name}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Birthday</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.bod}</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Gender</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.gender}</span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Height</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.height / 10}ft</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Ethnicity</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.ethnicity}</span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Religion</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.religion}</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Caste</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.caste}</span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Civil Status</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.civilstatus}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Residency
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">
                  Country Of Residence
                </span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{country}</span>
                </div>
              </div>

            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">
                  State / District
                </span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{state}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Education & Profession
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">
                  Education Level
                </span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">
                    {add.education_profession?.education}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Profession</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">
                    {add.education_profession?.profession}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Habits
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Drinking</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.habits?.drinking}</span>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">
                  Food Preference
                </span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.Food_prference}</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Smoking</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.habits?.drinking}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Description
            </h1>
            <div className="w-full gap-5 md:gap-10"></div>

            <div className="w-full gap-5 md:gap-10">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Description</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5]">
                  <span className="text-[#747474]">{add.description}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Images
            </h1>
            <div className="grid w-full grid-cols-2 gap-5 md:gap-10 md:grid-cols-4">
              {add.images && add.images.length > 0 ? (
                add.images.map((i, index) => (
                  <img
                    key={index}
                    src={i.imageUrl}
                    alt="/"
                    className="rounded-md"
                  />
                ))
              ) : (
                <p>Not Images added</p>
              )}
            </div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Father's Details
            </h1>
            {!add.father ? ('Father Details Not enter') : (
              <>
                <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Ethnicity</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.father?.ethnicity}</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Religion</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.father?.religion}</span>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Profession</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.father?.profession}</span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Profession</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.father?.name}</span>
                    </div>
                  </div>
                </div>

              </>)}


          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Mother's Details
            </h1>

            {!add.mother ? ('Mother Details Not Added') : (

              <>
                <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Ethnicity</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">
                        {add.mother?.ethnicity}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Religion</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.mother?.religion}</span>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Profession</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">
                        {add.mother?.profession}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <span className="font-medium text-[#444444]">Profession</span>
                    <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                      <span className="text-[#747474]">{add.mother?.name}</span>
                    </div>
                  </div>
                </div>
              </>
            )}


            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2"></div>
          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Horoscope Details
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Birthday</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.bod}</span>
                </div>
              </div>

            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">

              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Birth Time</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.horoscope?.birth_time} </span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">

            </div>

          </div>

          <div className="flex flex-col w-full gap-5 px-5 py-10 bg-white rounded-md shadow-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Contact details
            </h1>
            <div className="w-full gap-5 md:gap-10">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">Phone Number</span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">0{add.contact_info
                    ?.mobile}</span>
                </div>
              </div>
            </div>

            <div className="w-full gap-5 md:gap-10">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#444444]">
                  Email Address
                </span>
                <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2">
                  <span className="text-[#747474]">{add.contact_info
                    ?.email}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-row justify-end items-end w-full mt-2'>
            <div className='flex flex-row'>
              <Button color="#D5DBDB" onClick={() => submit()}>
              Send Request &nbsp; <FcLike />
              </Button>
              
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Singleview2;
