import React, { useEffect, useState } from "react";

//import mainbackimage from "../../assets/images/mainbackimage.png";

import { getMyAddvertisment } from "../../functions/addvertismentt";
import { Country, State } from "country-state-city";
import { useSelector } from "react-redux";

const Singleview3 = () => {
  const [add, setAdd] = useState({});
  const [loading, setLoading] = useState(false);
  const [country,setCountry] = useState('');
  const [state,setState] = useState('')

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const addview = async () => {
      setLoading(true);
      const response = await getMyAddvertisment(user.token);
      setAdd(response);
      getContry(response.residency?.country);
      getState(response?.residency?.state,response.residency?.country)
      setLoading(false);
    };
    addview();
  }, [user.token]);

  const getContry =(countryCode) =>{
    const selectedCountry = Country.getCountryByCode(countryCode);
    setCountry(selectedCountry.name)
  }

  const getState = (stateCode,countryCode) =>{
    const state = State.getStateByCodeAndCountry(stateCode,countryCode)
    setState(state.name)
  }

  return (
    <>
    <div className="flex flex-col gap-10 px-3 pt-10 md:px-10 lg:px-16 xl:px-24">
      <div className="w-full bg-[#E8B202] py-5 shadow-xl rounded-md">
        <h1 className="text-white  text-2xl font-medium text-center">
          {loading ? null : `My Ad`}
        </h1>
      </div>
    </div>

    <div
      className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24"
     // style={{ backgroundImage: `url(${mainbackimage})` }}
    >
      <div className="flex flex-col justify-center  w-full gap-10">
        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Basic
          </h1>
          <div className="grid grid-cols-1  gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Display Name</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">
                {add.first_name} {add.last_name}
              </span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Birthday</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.bod}</span>
              {/* </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Gender</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.gender}</span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Height</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.height / 10}ft</span>
              {/* </div> */}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Ethnicity</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.ethnicity}</span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Religion</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.religion}</span>
              {/* </div> */}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Caste</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.caste}</span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Civil Status</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.civilstatus}</span>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Residency
          </h1>
          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">
                Country Of Residence
              </span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{country}</span>
              {/* </div> */}
            </div>
    
          </div>

          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">
                State / District
              </span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{state}</span>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Education & Profession
          </h1>
          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">
                Education Level
              </span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">
                {add.education_profession?.education}
              </span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Profession</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">
                {add.education_profession?.profession}
              </span>
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Habits
          </h1>
          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Drinking</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.habits?.drinking}</span>
              {/* </div> */}
            </div>
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">
                Food Preference
              </span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.Food_prference}</span>
              {/* </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Smoking</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
              <span className="text-black">{add.habits?.drinking}</span>
              {/* </div> */}
            </div>
          </div>
        </div>
        {add.description ? (
        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Description
          </h1>
          <div className="w-full gap-5 md:gap-10"></div>

          <div className="w-full gap-5 md:gap-10">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-[#747474]">Description</span>
              {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5]"> */}
              <span className="text-black">{add.description}</span>
              {/* </div> */}
            </div>
          </div>
        </div>
        ) :(null)}

        {add.images && add.images.length > 0 ? (
          <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Images
            </h1>
            <div className="grid w-full grid-cols-2 gap-5 md:gap-10 md:grid-cols-4">
              {add.images.map((i, index) => (
                <img
                  key={index}
                  src={i.imageUrl}
                  alt="/"
                  className="rounded-md"
                />
              ))}
            </div>
          </div>
        ) : null}

        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Father's Details
          </h1>
          {!add.father ? (
            "Father Details Not enter"
          ) : (
            <>
              <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Ethnicity
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.father?.ethnicity}</span>
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">Religion</span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.father?.religion}</span>
                  {/* </div> */}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Profession
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.father?.profession}</span>
                  {/* </div> */}
                </div>

                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Profession
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.father?.name}</span>
                  {/* </div> */}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
          <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
            Mother's Details
          </h1>

          {!add.mother ? (
            "Mother Details Not Added"
          ) : (
            <>
              <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Ethnicity
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.mother?.ethnicity}</span>
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">Religion</span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.mother?.religion}</span>
                  {/* </div> */}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Profession
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.mother?.profession}</span>
                  {/* </div> */}
                </div>

                <div className="flex flex-col gap-1">
                  <span className="font-medium text-[#747474]">
                    Profession
                  </span>
                  {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                  <span className="text-black">{add.mother?.name}</span>
                  {/* </div> */}
                </div>
              </div>
            </>
          )}

          <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2"></div>
        </div>
        {!add.horoscope ? null : (
          <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Horoscope Details
            </h1>
            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#747474]">Birthday</span>
                {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                <span className="text-black">{add.bod}</span>
                {/* </div> */}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#747474]">Birth Time</span>
                {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                <span className="text-black">
                  {add.horoscope?.birth_time} am
                </span>
                {/* </div> */}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-5 md:gap-10 md:grid-cols-2"></div>
          </div>
        )}
        {!add.contact_info ? null : (
          <div className="flex flex-col w-full gap-5 px-10 md:px-20 lg:px-48 py-10 bg-[#eeeaea] rounded-md">
            <h1 className="text-[#3B3B3B]  text-xl font-bold text-center pb-10">
              Contact details
            </h1>
            <div className="w-full gap-5 md:gap-10">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#747474]">
                  Phone Number
                </span>
                {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                <span className="text-black">
                  0{add.contact_info?.mobile}
                </span>
                {/* </div> */}
              </div>
            </div>

            <div className="w-full gap-5 md:gap-10">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[#747474]">
                  Email Address
                </span>
                {/* <div className="px-2 py-1 rounded-md bg-[#E5E5E5] md:w-1/2"> */}
                <span className="text-black">{add.contact_info?.email}</span>
                {/* </div> */}
              </div>
            </div>
          </div>
        )}
        {/* <div className='flex items-center justify-between w-full'>
                        <div className='flex items-center gap-2'>
                            <IoHeartOutline size={30} className='text-rose-500' />
                            <span>Like</span>
                        </div>


                        <div className='flex items-center gap-2'>
                            <Button variant='outline' color='#EACC00'>Contact</Button>
                            <Button variant='filled' color='#EACC00'>Message</Button>
                        </div>
                    </div> */}
      </div>
    </div>
  </>
  );
};

export default Singleview3;
