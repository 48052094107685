import React, { useEffect, useState } from "react";
import BlogCard from "../../components/cards/BlogCard";
import { showBlogs } from "../../functions/blogs";

const Blogs = () => {
  useEffect(() => {
    loadmore();
  }, []);

  const loadmore = async () => {
    const response = await showBlogs();
    setBlog(response);
  };
  const [blog, setBlog] = useState([]);

  return (
    <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24">
      <div className="w-full bg-[#E8B202] py-4 shadow-xl rounded-md javanese_font">
        <h1 className="text-white  text-2xl font-bold text-center ">Blogs</h1>
      </div>

      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
        {blog.map((b) => (
          <BlogCard b={b} key={b._id}/>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
