import React, { useState } from "react";
import loginimagere2 from "../../assets/images/loginimagere2.png";
import { Button,Input  } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../nav/Header";
import { sendOtpEmail } from "../../functions/auth";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../app/features/userSlice";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
const LoginWithEmail = () => {
  const [email, setEmail] = useState("");
  const [view, setView] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const sendSubmit = async () => {
    try {
      setLoading(true);
      const response = await sendOtpEmail(email);
      setLoading(false);
      if ((response.message = "success")) {
        setView(true);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error.message)
    }
  
  };

  const { status, error } = useSelector((state) => state.user);

  const handleLogin = async () => {
    const res = await dispatch(login({ email, otp }));
    if (res.payload.user) {
      roleBasedRedirect(res.payload);
    }
  };

  const roleBasedRedirect = (data) => {
    const intended = location.state?.from;
    if (intended) {
      navigate(intended);
    } else {
      if (data.user?.role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <>
      <Header />

      <div className="w-full h-[100vh] flex flex-col justify-between gap-10">
        <div className="flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font">
          <span className="text-lg text-white">Welcome to</span>
          <h1 className="text-3xl text-white ">Thuhina.lk</h1>
        </div>

        <div className="flex justify-center w-full">
          <div className="relative rounded-lg shadow-lg w-[98%] sm:w-[80%]  md:w-[60%] lg:w-[50%]  flex bg-[#F9F9F9]">
            {/* <img src={Bg_top} alt="/" />
            <img src={Bg_bottom} alt="/" className="hidden md:flex" /> */}

            <img src={loginimagere2} alt="/" className="md:flex" />

            <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full gap-5 py-10">
              <h1
                className={`${error ? "text-[#8a2929] " : "text-[#3B3B3B] "
                  }text-center text-2xl font-medium`}
              >
                {error ? error : "  Welcome Back !"}
              </h1>
              {view ? null : (
                <div className="flex flex-col gap-3 px-3 md:px-20 items-center">
                  <span className="text-[#3B3B3B]">
                    Enter Your Email Address Here
                  </span>
                  <span className="text-[#3B3B3B]">
                  (ඔබගේ විද්‍යුත් තැපැල් ලිපිනය මෙහි ඇතුලත් කරන්න)
                  </span>
                  <div className="flex">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full bg-transparent border-b outline-none"
                      placeholder="Ex: example@gmail.com"
                    />
                  </div>
                </div>
              )}

              {view ? (
                <>
                  <div className="flex flex-col gap-3 px-3 md:px-20 items-center">
                    <span className="text-[#3B3B3B]">
                      Enter your OTP code that we send to your Email
                    </span>
                    <div className="flex gap-5">
                      <div className="flex">
                        <Input
                          type="number"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center w-full">
                    <div className="w-1/3 md:w-1/5">
                      <Button
                        variant="filled"
                        color="#EACC00"
                        className="text-black"
                        fullWidth
                        onClick={() => handleLogin()}
                      >
                        {status === "loading" ? (
                          <PulseLoader color="#fffff" size={5} />
                        ) : (
                          "verify"
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex justify-center w-full">
                  <div className="w-1/3 md:w-1/5">
                    <Button
                      variant="filled"
                      color="#EACC00"
                      className="text-black"
                      fullWidth
                      onClick={() => sendSubmit()}
                    >
                      {loading ? (
                        <PulseLoader color="#ffffff" size={5} />
                      ) : (
                        "Send Code"
                      )}
                    </Button>
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-1">
                <Link to="/login/mobile">
                  <div className="w-full text-center">
                    <span className="text-[#3B3B3B] underline text-center text-sm">
                      Log In With Mobile Verification
                    </span>
                  </div>
                </Link>
                {/* <Link to="/login/username">
                  <div className="w-full text-center">
                    <span className="text-[#3B3B3B] underline text-center text-sm">
                      Log In With Password
                    </span>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full py-5 text-center text-[#595959]">
          <span className="">By signing up you are agreeing to our</span>
          <div className="flex justify-center w-full gap-1 text-center">
            <Link to="/privacy">
              <span>Privacy Policy </span>
            </Link>
            <span>and</span>
            <Link to="/terms&conditions">
              <span>Terms of Use.</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginWithEmail;
