import { Input, Table } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { histroyAllPayments } from "../../../functions/payments";
import moment from "moment";
import Loading from "../../../components/Loading";

const PaymentHistory = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const { user } = useSelector((state) => state.user);
  const loadPaymentHistory = useCallback(async () => {
    setLoading(true);
    const response = await histroyAllPayments(user.token, "male");
    console.log(response);
    setPayments(response);
    setLoading(false);
  }, [user.token]);
  useEffect(() => {
    loadPaymentHistory();
  }, [loadPaymentHistory]);

  const rows = payments.map((element) => (
    <Table.Tr key={element._id}>

      <Table.Td>{element.user?.email}</Table.Td>
      <Table.Td>{element.user?.mobile}</Table.Td>
      <Table.Td>
        {moment(element.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
      </Table.Td>
     
      <Table.Td>{element.package?.name ?element.package?.name: null } {element.premium?.name ?element.premium?.name: null }</Table.Td>
      <Table.Td>Rs.{element.package?.price ? element.package?.price : null} {element.premium?.price ? element.premium?.price : null} </Table.Td>
    </Table.Tr>
  ));

  return (
    <div className="flex flex-col gap-10">
      <h1 className="text-xl font-medium">
        {loading ? "loading" : "Payment History"}
      </h1>

      <div className="flex items-center justify-end w-full">
        <Input
          placeholder="Search ad from UserID"
          rightSection={<IoSearchSharp />}
        />
      </div>
{loading ? <Loading/> :    <div className="overflow-x-scroll bg-white scroll-smooth scrollbar-hide">
        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Email </Table.Th>
              <Table.Th>User Id</Table.Th>
              <Table.Th>Payment Date</Table.Th>
       
              <Table.Th>Package</Table.Th>
                    <Table.Th>Price</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>}
  
    </div>
  );
};

export default PaymentHistory;
