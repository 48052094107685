import React, { useCallback, useEffect, useState } from 'react'
import Vector1 from '../../../assets/images/Auser.png'
import Vector2 from '../../../assets/images/man.png'
import Vector3 from '../../../assets/images/famale.png'
import Ads from '../../../assets/images/ads.png'
import { getAllUserCount, getAllUserCountGender, getRunnigAdds } from '../../../functions/admin'
import { useSelector } from 'react-redux'


const Dashboard = () => {
    const {user} = useSelector((state) => state.user)
    const [loading,setLoading] = useState(false)
    const [usercount ,setUserCount] = useState('')
    const [male,setMale] = useState('');
    const [addcount,setAddCount] = useState('')


    const loadMailCount = useCallback(async () => {
        setLoading(true);
        
        const response = await getAllUserCountGender(user.token,"male");
        console.log(response)
       setMale(response)
        setLoading(false)
    
      }, [user.token]);


    const loadAddCount = useCallback(async () => {
        setLoading(true);
        
        const response = await getRunnigAdds(user.token);
   setAddCount(response)
     
        setLoading(false)
    
      }, [user.token]);

      
    const loadUsercount = useCallback(async () => {
        setLoading(true);
        
        const response = await getAllUserCount(user.token);
        setUserCount(response)
        setLoading(false)
    
      }, [user.token]);

      
    
      useEffect(() => {
        loadUsercount()
        loadMailCount()
        loadAddCount()
      }, [loadUsercount,loadMailCount,loadAddCount]);
    
  return (
    <div className='flex flex-col gap-10'>
      <h1 className='text-xl font-medium'>{loading ?'loading':'dashboard'}</h1>

      <div className='grid grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-3'>

        {/*  */}
        {/* <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>Total Earnings</span>
                <h1 className='text-xl font-medium'>$20.4K</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Vector1} alt='/'/>
            </div>
        </div> */}

        {/*  */}
        {/* <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>Last Months Earnings</span>
                <h1 className='text-xl font-medium'>$8.4K</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Vector2} alt='/'/>
            </div>
        </div> */}

        {/*  */}
        <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>Total Running Ads</span>
                <h1 className='text-xl font-medium'>{addcount}</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Ads} alt='/'/>
            </div>
        </div>

         {/*  */}
         <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>All Users</span>
                <h1 className='text-xl font-medium'>{usercount}</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Vector1} alt='/'/>
            </div>
        </div>

         {/*  */}
         <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>Total Male Users</span>
                <h1 className='text-xl font-medium'>{male}</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Vector2} alt='/'/>
            </div>
        </div>

          {/*  */}
          <div className='flex p-3 bg-white'>
            <div className='flex flex-col w-3/4 gap-3'>
                <span>Total Female Users</span>
                <h1 className='text-xl font-medium'>{usercount - male}</h1>
            </div>
            <div className='flex items-center justify-center w-1/4'>
                <img src={Vector3} alt='/'/>
            </div>
        </div>
        
        
      </div>
    </div>
  )
}

export default Dashboard
