import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet} from "react-router-dom";
import { cuurentAdmin } from "../../functions/auth";
import LoadingToRedirect from "./LoadingRederict";




export const AdminRoute = ({children ,...rest }) =>{
    const {user} = useSelector((state) =>  state.user);
    const [ok,setOk] = useState(false);

    useEffect(() =>{
        if(user && user.token){
            cuurentAdmin(user.token).then((res) =>{
                console.log("CURRENT ADMIN RES",res);
                setOk(true)
            }).catch((err) =>{
                console.log("ADMIN >ROUTE ERR",err);
                setOk(false)
            })
        }
    },[user]);

    return ok ? <Outlet/> : <LoadingToRedirect/>

}