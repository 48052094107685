import { Button, Select, TextInput, Textarea, Input } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Badge, Avatar } from "antd";
import { Country, State } from "country-state-city";
import {
  getMyAddvertisment,
  updateMyAddvertismnet,
} from "../../functions/addvertismentt";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import toast from "react-hot-toast";
const EditAdvertisement = () => {
  const [first_name, setFname] = useState("");
  const [last_name, setLname] = useState("");
  const [bod, setBod] = useState("");
  const [gender, setGender] = useState("");
  const [ethnicity, setEthc] = useState("");
  const [religion, setRel] = useState("");
  const [caste, setCaste] = useState("");
  const [civilstatus, setCivil] = useState("");
  const [height, setHeight] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [images, setImages] = useState([]);
  const [education, setEducation] = useState("");
  const [profession, setPropc] = useState("");
  const [drinking, setDirking] = useState("");
  const [smoking, setSmoking] = useState("");
  const [father_name, setFathername] = useState("");
  const [father_ethnicity, setFatherEtc] = useState("");
  const [father_caste, setFathercaste] = useState("");
  const [father_religon, setFatherReleigon] = useState("");
  const [father_profession, setFatherPropc] = useState("");
  const [mother_name, setMothername] = useState("");
  const [mother_ethnicity, setMotherEtc] = useState("");
  const [mother_caste, setMothercaste] = useState("");
  const [mother_religon, setMotherReleigon] = useState("");
  const [mother_profession, setMotherPropc] = useState("");
  const [birth_time, setBoTime] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [Food_prference, setFood] = useState("");
  const [description, setDescription] = useState("");
  const [age, setAge] = useState("");
  const [addId, setAddId] = useState("");
  const [loading, setLoading] = useState("");
  const { user } = useSelector((state) => state.user);

  const countries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));

  const states = State.getStatesOfCountry(country).map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));

  useEffect(() => {
    const addview = async () => {
      setLoading(true);
      const response = await getMyAddvertisment(user.token);
      setFname(response.first_name);
      setLname(response.last_name);
      setBod(response.bod);
      setGender(response.gender);
      setEthc(response.ethnicity);
      setGender(response.gender);
      setRel(response.religion);
      setFood(response.Food_prference);
      setCaste(response.caste);
      setCivil(response.civilstatus);
      setCountry(response?.residency?.country);
      setState(response?.residency?.state);
      setImages(response.images);
      setEducation(response.education_profession?.education);
      setPropc(response.education_profession?.profession);
      setDirking(response.habits?.drinking);
      setBoTime(response.horoscope?.birth_time);
      setFathername(response.father?.name);
      setFatherEtc(response.father?.ethnicity);
      setFatherReleigon(response.father?.religion)
      setFathercaste(response.father?.caste);
      setFatherPropc(response.father?.profession);
      setMothername(response.mother?.name);
      setMotherEtc(response.mother?.ethnicity);
      setMothercaste(response.mother?.caste);
      setMotherPropc(response.mother?.profession);
      setMotherReleigon(response.mother?.religion)
      setMobile(response.contact_info?.mobile);
      setEmail(response.contact_info?.email);
      setAge(response.age);
      setDescription(response.description);
      setSmoking(response.habits?.smoking);
      setHeight(response.height / 10);
      setCivil(response.civilstatus);
      setAddId(response._id);
      getContry(response.residency?.country)
      getState(response.residency?.state, response.residency?.country)
    };
    addview();
  }, [user.token]);

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    setState("");
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState);
  };

  const ethnicities = ["Sinhalese", "Tamil", "Muslim", "Burgher", "Other"];

  const religions = [
    "Buddihist",
    "Christian",
    "Hindu",
    "Islam",
    "Agnostic",
    "Catholic",
    "None",
    "Other",
  ];
  const professions = [
    "Accountant",
    "Architect",
    "Banker",
    "Business Owner",
    "Chef",
    "Docotor",
    "Driver",
    "Engineer",
    "Farmer",
    "Government Service",
    "Graphic Designer",
    "Hotelier",
    "Hose Wife",
    "IT Professional",
    "Lawyer",
    "Lecturer",
    "Manager",
    "Medical Professional",
    "Miltary",
    "Not Employed",
    "Nurse",
    "Other",
    "Police Officer",
    "Quantity Surveyor",
    "Researcher",
    "Sales Executive",
    "Self Employed",
    "Surveyor",
    "Teacher",
    "Technician",
  ];
  const educations = [
    "Up to GCE O/L",
    "Up to GCE A/L",
    "Diploma",
    "Professional Qualification",
    "Undergraduate",
    "Bachelor's Degree or Equivalent",
    "Post Graduate Diploma",
    "Master's Degree or Equivalent",
    "Phd or Post Doctoral",
  ];

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCloudinaryUpload = async (e, index) => {
    const files = e.target.files;

    const base64Images = await Promise.all(
      Array.from(files).map((file) => convertBase64(file))
    );

    try {
      setLoading(true);
      const responses = await Promise.all(
        base64Images.map((base64) =>
          axios.post(`${process.env.REACT_APP_API}/user/uploadimag`, {
            image: base64,
          })
        )
      );
      setLoading(false);

      const newVariants = [];
      responses.forEach((response, i) => {
        newVariants.push({
          imageUrl: response.data.url,
          publicId: response.data.public_id,
        });
      });
      setImages(newVariants);

      console.log(newVariants);
    } catch (error) {
      console.log("Error uploading image to Cloudinary:", error);
    }
  };

  const removeCloudinaryUpload = async (public_id) => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API}/user/removeImg`, {
        public_id,
      });
      setLoading(false);

      const newVariants = images.filter(
        (image) => image.publicId !== public_id
      );
      setImages(newVariants);
    } catch (error) {
      console.log("Error removing image from Cloudinary:", error);
    }
  };
  const submit = async () => {
    const response = await updateMyAddvertismnet(
      user.token,
      addId,
      first_name,
      last_name,
      bod,
      gender,
      ethnicity,
      religion,
      caste,
      civilstatus,
      height,
      country,
      Food_prference,
      description,
      state,
      images,
      education,
      profession,
      drinking,
      smoking,
      father_name,
      father_ethnicity,
      father_caste,
      father_religon,
      father_profession,
      mother_name,
      mother_ethnicity,
      mother_caste,
      mother_religon,
      mother_profession,
      birth_time,
      mobile,
      email,
      age
    );
    toast.success(response.message)
  };

  const getContry = (countryCode) => {
    const selectedCountry = Country.getCountryByCode(countryCode);
    setCountry(selectedCountry.name)
  }

  const getState = (stateCode, countryCode) => {
    const state = State.getStateByCodeAndCountry(stateCode, countryCode)
    setState(state.name)
  }

  return (
    <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-32">
      <div className="w-full bg-[#E8B202] py-4 shadow-xl rounded-md">
        <h1 className="text-white  text-2xl font-medium text-center">
          New Add
        </h1>
      </div>
      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">Basic</h1>

        {/* --------------------------------------------------------User Details------------------------------------------------------- */}
        <div className="w-full mt-3 rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="First Name (මුල් නම)"
              value={first_name}
              onChange={(e) => setFname(e.target.value)}
              placeholder="Enter your first name"
              className="md:w-1/2"
            />
            <TextInput
              label="Last Name (අවසන් නම)"
              value={last_name}
              onChange={(e) => setLname(e.target.value)}
              placeholder="Enter your last name"
              className="md:w-1/2"
            />
            <div className="md:w-[30%] flex flex-col">
              <span className="font-medium text-[14px]">Birthday (උපන් දිනය)</span>
              <Input
                
                type="date"
                value={bod}
                onChange={(e) => setBod(e.target.value)}
                placeholder="Enter your last name"
                className="md:w-full"
              />
            </div>

          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Gender (ස්ත්‍රී පුරුෂ භාවය)"
              placeholder="Select your gender"
              value={gender}
              onChange={(value) => setGender(value)}
              data={["Male", "Female"]}
              className="md:w-1/2"
            />
            <Select
              label="Ethnicity (ජාතිය)"
              placeholder={ethnicity}
              data={ethnicities}
              onChange={(value) => setEthc(value)}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Height (උස - Ex: 5.6)"
              placeholder="Select your Height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              className="md:w-1/2"
            />
            <Select
              label="Religion (ආගම)"
              placeholder={religion}
              onChange={(value) => setRel(value)}
              data={religions}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Civil Status (සිවිල් තත්වය)"
              placeholder={civilstatus}
              data={[
                "Never Married",
                "Widowed",
                "Divorced",
                "Separated",
                "Other",
              ]}
              onChange={(value) => setCivil(value)}
              className="md:w-1/2"
            />
            <TextInput
              label="Caste (කුලය)"
              value={caste}
              onChange={(e) => setCaste(e.target.value)}
              placeholder="(Optional)"
              className="md:w-1/2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">Residency (පදිංචිය)</h1>

        <div className="w-full mt-3 rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Country (රට)"
              placeholder={country}
              onChange={(value) => handleCountryChange(value)}
              data={countries}
              className="md:w-1/2"
            />

            <Select
              label="State (ස්ථානය)"
              placeholder={state}
              onChange={(value) => handleStateChange(value)}
              data={states}
              className="md:w-1/2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">
          Education & Profession (අධ්‍යාපනය සහ වෘත්තිය)
        </h1>

        <div className="w-full rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Education Level (අධ්‍යාපනය මට්ටම)"
              placeholder={education}
              onChange={(value) => setEducation(value)}
              data={educations}
              className="md:w-1/2"
            />
            <Select
              label="Profession (වෘත්තිය)"
              placeholder={profession}
              onChange={(value) => setPropc(value)}
              data={professions}
              className="md:w-1/2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">Habits (පුරුදු)</h1>

        <div className="w-full  rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Drinking ( මද්‍යසාර පානය)"
              placeholder={drinking}
              onChange={(value) => setDirking(value)}
              data={["Yes", "No"]}
              className="md:w-1/2"
            />
            <Select
              label="Smoking (දුම්පානය)"
              onChange={(value) => setSmoking(value)}
              placeholder={smoking}
              data={["Yes", "No"]}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Food Preference (ආහාර මනාපය)"
              placeholder={Food_prference}
              onChange={(value) => setFood(value)}
              data={["Vagitarian", "Non-vegitarian"]}
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        {/* <h1 className="text-[#3B3B3B]  text-lg font-medium">Add Description</h1> */}

        <div className="w-full  rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Are you creating this ad for a differently abled person ? (ඔබ මෙම දැන්වීම නිර්මාණය කරන්නේ ආබාධිත පුද්ගලයෙකු වෙනුවෙන්ද?)"
              placeholder="Select"
              data={["Yes", "No"]}
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Textarea
              label="Description (ඔබගෙ විස්තරය)"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Write a description"
              autosize
              minRows={5}
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">Upload Images (ඔබගෙ පිංතුරය ඇතුලත් කරන්න)</h1>

        <div className="w-full  rounded-md  bg-[#F9F9F9] flex px-5 gap-5 py-10 flex-col md:flex-row items-center justify-center">
          {images.map((image) => (
            <Badge
              count="x"
              style={{ cursor: "pointer" }}
              className="mb-3"
              onClick={() => removeCloudinaryUpload(image.publicId)}
            >
              <Avatar
                key={image.publicId}
                src={image.imageUrl}
                size={350}
                shape="square"
              />
            </Badge>
          ))}

          <input
            type="file"
            name="slip"
            id="slip"
            className="hidden"
            onChange={(e) => handleCloudinaryUpload(e)}
          />

          <label
            htmlFor="slip"
            className="py-[10px] px-[40px] bg-[#E8B202] hover:bg-[#E8B202] w-fit text-white rounded-md cursor-pointer "
          >
            <span>{loading ? <PulseLoader size={6} /> : "Upload your photo"}</span>
          </label>
        </div>
      </div>

      {/* -------------------------------------------------------------Father Details----------------------------------------------------- */}
      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">
          Parent's Informations (දෙමාපියන්ගේ තොරතුරු)
        </h1>

        <h1 className="text-[#3B3B3B]  mt-3 font-medium">Father (පියා)</h1>
        <div className="w-full rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Ethnicity (ජාතිය)"
              placeholder={father_ethnicity}
              onChange={(value) => setFatherEtc(value)}
              data={ethnicities}
              className="md:w-1/2"
            />
            <Select
              label="Religion (ආගම)"
              placeholder={father_religon}
              onChange={(value) => setFatherReleigon(value)}
              data={religions}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Profession (වෘත්තිය)"
              placeholder={father_profession}
              onChange={(value) => setFatherPropc(value)}
              data={professions}
              className="md:w-1/2"
            />
          </div>

          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              value={father_caste}
              onChange={(e) => setFathercaste(e.target.value)}
              label="Caste (කුලය)"
              placeholder="Caste"
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              value={father_name}
              onChange={(e) => setFathername(e.target.value)}
              label="Father Name (පියාගේ නම)"
              placeholder="Father Name"
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>
        </div>

        {/* ------------------------------------------------------------Mother Details--------------------------------------------------- */}
        <h1 className="text-[#3B3B3B]  mt-3 font-medium">Mother (මව)</h1>
        <div className="w-full rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Ethnicity (ජාතිය)"
              placeholder={mother_ethnicity}
              data={ethnicities}
              onChange={(value) => setMotherEtc(value)}
              className="md:w-1/2"
            />
            <Select
              label="Religion (ආගම)"
              placeholder={mother_religon}
              data={religions}
              onChange={(value) => setMotherReleigon(value)}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <Select
              label="Profession (වෘත්තිය)"
              placeholder={mother_profession}
              onChange={(value) => setMotherPropc(value)}
              data={professions}
              className="md:w-1/2"
            />
          </div>
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Mother Name (මවගේ නම)"
              value={mother_name}
              onChange={(e) => setMothername(e.target.value)}
              placeholder="Mother Name"
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>

          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Caste (කුලය)"
              value={mother_caste}
              onChange={(e) => setMothercaste(e.target.value)}
              placeholder="Caste"
              className="md:w-1/2"
            />
            <div className="md:w-1/2"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-2">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">
          Horoscope Informations (කේන්දර තොරතුරු)
        </h1>
        <h1 className="text-[#3B3B3B]  text-[16px] font-medium">Birth Time (උපන් වේලාව)</h1>

        <div className="w-full  rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <input
              type="time"
              value={birth_time}
              onChange={(e) => setBoTime(e.target.value)}
              placeholder="Birth Time"
              className="md:w-1/2 border-2 p-1"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center w-full gap-3">
        <h1 className="text-[#3B3B3B]  text-lg font-medium">
          Contact Informations
        </h1>

        <div className="w-full  rounded-md  bg-[#F9F9F9] flex flex-col px-5 gap-5 py-10">
          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Phone Number (දුරකථන අංකය)"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="+94 345 56 78"
              className="w-ful md:w-1/3"
            />
          </div>

          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Email (විද්‍යුත් තැපෑල)"
              placeholder="test@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-ful md:w-1/3"
            />
          </div>

          <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
            <TextInput
              label="Age (වයස)"
              placeholder="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              className="w-ful md:w-1/3"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <Button variant="outline" color="#9D9D9D">
          <Link to="/user/advertisement">Back</Link>
        </Button>
        <div className="w-44" onClick={() => submit()}>
          <Button variant="filled" color="#EACC00" fullWidth>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditAdvertisement;
