import React from 'react'

import { Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import moment from 'moment'

const BlogCard = ({b}) => {
    const formattedCreatedAt = moment(b.createdAt).fromNow()

    return (
        <div>
            <div className='flex flex-col shadow-lg rounded-b-xl'>
                {b.images.map((i) =>(
                    <img src={i.imageUrl} alt='/' />
                ))}
                
                <div className='flex flex-col gap-5 p-5'>
                    <h1 className='text-xl font-medium poppins'>{b.title}</h1>
                   
                    <div className='flex items-end justify-between'>
                        <span className='text-sm'>{formattedCreatedAt}</span>
                        <Link to={`/blogs/single/${b._id}`}><Button variant='filled' color='#EACC00'>Read Blog</Button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCard
