import React from 'react'
import { Link } from 'react-router-dom'


const Privacy = () => {
    return (
        <>
            <div className='flex flex-col w-full gap-10 pb-10 mb-12'>

                <div className='flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font'>
                    <h1 className='text-4xl text-white font-bold'> Privacy Policy</h1>
                    <span className='text-lg text-white'>Lastly Updated on 3 / 4 / 2024</span>
                </div>

                <div className='flex flex-col gap-10 px-3 md:px-10 lg:px-16 xl:px-24'>
                    <div className='flex flex-col gap-7'>
                        <h1 className='text-lg font-medium'>Privacy Policy:</h1>
                        <p className='text-[#696969]'>Email address, full name, telephone number, ID, postal address, date of birth, height, marital status and other personal information and data provided by you. This is information obtained from thuhina.lk to verify the accuracy of personal data.</p>
                        <p className='text-[#696969]'>We also store your message exchange data in thuhina.lk, thuhina.lk is not obliged to obtain or provide it to any party and we are only obliged to provide it as required by existing law.</p>
                        <p className='text-[#696969]'>The information provided by our customers will be shared with thuhina.lk members with utmost care and high security to provide quality and reliable service..</p>
                        <p className='text-[#696969]'>All information provided by you is stored in a secure system where only authorized persons can enter. Maximum protection of your personal information is provided by thuhina.lk</p>
                        <p className='text-[#696969]'>Privacy Policy thuhina.lk may be revised from time to time at its sole discretion and without notice. The revised privacy policy will be effective as soon as it is posted on thuhina.lk and if anyone uses the website from then on, it will be your acceptance of the revised privacy policy. considered as.</p>
                        <Link to="/sinhala-privacy" className='underline'>Translate to sinhala</Link>
                    </div>
                    
                </div>


            </div>
        </>
    )
}

export default Privacy
