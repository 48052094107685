import React, { useEffect, useState } from "react";
import PopularPackageCard from "../components/cards/PopularPackageCard";
import { viewPackages } from "../functions/addPakckge";
import backgroundImagep from "../assets/images/pngwing66.png";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [loading,setLoading]= useState(false)

  useEffect(() => {
    loadPackages();
  }, []);


  const loadPackages = async () => {
    setLoading(true)
    const response = await viewPackages();
    setPackages(response);
    setLoading(false)
  };

  return (
    <>
      <div className="flex flex-col gap-10 px-3 py-10 md:px-10 lg:px-16 xl:px-24">
        <div className="w-full bg-[#E8B202] py-5 shadow-xl rounded-md">
          <h1 className="text-white  text-2xl font-bold text-center ">Packages</h1>
        </div>

        <div className="flex flex-col gap-10">
          <div className="flex justify-center w-full">
            <div className="rounded-lg shadow-lg  flex bg-[#F9F9F9] md:relative justify-between lg:w-4/5 w-full">
            <div
                className="top-0 left-0 grid justify-center w-full h-full grid-cols-1 gap-10 p-10 md:grid-cols-3"
                id="main"
                style={{
                  backgroundImage: `url(${backgroundImagep})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >

              {loading ? (
                  <div className="flex justify-center items-center w-full h-full">
                    <span className="text-xl font-medium text-[#3B3B3B]">Loading...</span>
                  </div>
                ) : (
                  packages.map((p) => <PopularPackageCard  p={p} />)
                )}


                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
