import axios from "axios";
import toast from "react-hot-toast";


export const createMagzine = async (title, images,pdfUrl,public_id ) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/magazine`,{
        title, images,pdfUrl,public_id 
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};


export const getMagzines = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/admin/magazines`
      );
  
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };
  

  export const deleteMagzine  = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/admin/magazine/${id}`
      );
  
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };
  

export const downloadMagazine = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/admin/magazine/${id}`
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};
