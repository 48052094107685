import React from 'react'
import Logo from '../../assets/images/thuhina logo6.png'
// import Logo2 from '../../assets/images/Group 1000003323.png'
import { MdLocalPhone, MdAlternateEmail } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import { FaFacebook, FaLocationDot, FaSquareInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='w-full flex flex-col gap-5 py-5 bg-[#F4F4F4]'>
            <div className='flex flex-col gap-5 px-3 md:px-10 lg:px-16 xl:px-20'>
                <div className='flex flex-col gap-10 md:gap-16 md:flex-row'>
                    <div className='flex flex-col w-full gap-5 md:w-2/5'>
                        <div className='flex gap-2'>
                            <img src={Logo} alt='/' className='w-20 ' />
                            {/* <img src={Logo2} alt='/' className='w-20 ' /> */}
                        </div>
                        <p className='text-[#ABABAB] text-justify'>ආදරනීය හදවත් රාශියක අතැගිලි එක් කල අපි පස් වසරක සේවා අත්දැකීම් තුලින් ඔබට සිතැගි සහකරු හෝ සහකාරිය ඉක්මනින්ම සොයා ගැනිමට "තුහින.lk"අපි කැපවෙන්නෙමු.ඔබ තුහින.lk වෙත පිය නැගීමෙන් ඔබගෙ විවහා අපේක්ෂාවන් ඉතා ඉක්මනින් ආරක්ෂාකාරීව ඉටු කර දිමට තුහින.lk කාර්‍ය්ලමංඩලය වන අපි ඔබ සමග අත්වැල් බැද ගන්නෙමු.</p>
                    </div>
                    <div className='flex flex-col w-full gap-10 md:w-3/5 lg:flex-row'>
                        <div className='flex w-full gap-5 lg:w-2/3 lg:gap-10'>
                            <div className='flex flex-col w-1/3 gap-5'>
                                <h1 className='text-[#6C6C6C] font-bold'>Links</h1>
                                <div className='flex flex-col text-[#ABABAB]'>
                                    <ul className='flex flex-col gap-2 text-sm'>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/">Home</Link></li>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/packages">Packages</Link></li>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/about">About Us</Link></li>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/contact">Contact Us</Link></li>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/magazine">Magazines</Link></li>
                                        <li className='hover:text-[#EACC00] hover:cursor-pointer'><Link to="/blogs">Blogs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex flex-col w-2/3 gap-5'>
                                <h1 className='text-[#6C6C6C] font-bold'>Contact Us</h1>
                                <div className='flex flex-col text-[#ABABAB] text-sm gap-2'>
                                    <div className='flex items-center gap-2'>
                                        <MdLocalPhone />
                                        <span>+94 78 765 4076</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <MdAlternateEmail />
                                        <span>lkthuhina@gmail.com</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <TbWorldWww />
                                        <span>www.thuhina.lk</span>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <FaLocationDot />
                                        <span>No 26-1 , kumbukwewa , kekirawa</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col w-full gap-5 lg:w-1/3'>
                            <h1 className='text-[#6C6C6C] font-bold'>Social Media</h1>
                            <div className='flex flex-col text-[#ABABAB] text-sm gap-2'>
                                <div className='flex  items-center gap-2'>
                                    
                                    <FaFacebook />
                                    <Link to='https://www.facebook.com/thuhina.lk'>
                                    <span>facebook</span>
                                    </Link>
                                    
                                </div>
                                <div className='flex items-center gap-2'>
                                  
                                    <FaSquareInstagram />
                                    <Link to='https://www.instagram.com/thuhina.lk'>
                                    <span>instagram</span>
                                    </Link>
                                    
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-end gap-2'>
                <Link to='/privacy'><span className='text-[#6C6C6C] font-bold'>Privacy Policy </span></Link>
                    <span>/</span>
                    <Link to='/terms&conditions'><span className='text-[#6C6C6C] font-bold'>Terms & Conditions</span></Link>
                </div>
            </div>
            <hr className='w-full' />
            <div className='flex justify-center px-3 md:px-10 lg:px-16 xl:px-20'>
                <span className='text-[#ABABAB] text-sm md:text-base text-center'>All Rights Reserved By Thuhina.lk - © 2024</span>
            </div>
        </div>
    )
}

export default Footer
