import { Button,Input } from "@mantine/core";
import React, { useState } from "react";
import {Avatar,Badge} from "antd"
import axios from "axios";
import toast from "react-hot-toast";
import { createMagzine } from "../../../functions/magazine";
import { useNavigate } from "react-router-dom";

const AddNewMagazine = () => {
  const [title, setTitle] = useState("");
  const [pdf, setPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [image, setImge] = useState([]);
  const [loading, setLoading] = useState(false);
 const navigate = useNavigate()
  const handleFileChange = (event) => {
    setPdf(event.target.files[0]);
  };

  const handleImagesChange = async (event) => {
    const files = event.target.files;
    const uploadImagePromises = Array.from(files).map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "Pdffile");

      return axios.post("https://api.cloudinary.com/v1_1/dqw4suvlx/upload", formData);
    });

    try {
      setLoading(true);
      const responses = await Promise.all(uploadImagePromises);
      const newImages = responses.map((response) => ({
        imageUrl: response.data.secure_url,
        publicId: response.data.public_id,
      }));
      setImages(newImages);
      toast.success("Images uploaded successfully!");
    } catch (error) {
      console.error("Error uploading images to Cloudinary:", error);
      toast.error("Failed to upload images.");
    } finally {
      setLoading(false);
    }
  };

   const sendMagasine = async (pdfUrl,public_id) => {
    const response = await createMagzine(title, images,pdfUrl,public_id );
    toast.success(response.message)
    setTitle("");
    navigate("/admin/Magazines_management")
   }

  const handlePdfUpload = async () => {
    if (!pdf) {
      toast.error("Please select a PDF to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", pdf);
    formData.append("upload_preset", "pdf_uploader");

    try {
      setLoading(true);
      const response = await axios.post("https://api.cloudinary.com/v1_1/dzud0fvwn/upload", formData);
      console.log(response)
      let pdfUrl = response.data.secure_url;
      let public_id = response.data.public_id;
      toast.success("PDF uploaded successfully!");
       console.log("PDF URL:", pdfUrl);
    await  sendMagasine(pdfUrl,public_id)
     
      // You can handle the PDF URL here (e.g., save it to your state or backend)
    } catch (error) {
      console.error("Error uploading PDF to Cloudinary:", error);
      toast.error("Failed to upload PDF.");
    } finally {
      setLoading(false);
    }
  };
 




  const removeCloudinaryUpload = async (public_id) => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API}/user/removeImg`, {
        public_id,
      });
      setLoading(false);

      const newVariants = image.filter((image) => image.publicId !== public_id);
      setImge(newVariants);
    } catch (error) {
      console.log("Error removing image from Cloudinary:", error);
    }
  };


  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-xl font-medium">Magazines Management</h1>
      <span>Add New Magazine</span>
      <form
        className="flex flex-col w-full gap-5 lg:w-2/3"
        onSubmit={(e) => {
          e.preventDefault();
          handlePdfUpload();
        }}
      >
        <div className="flex flex-col gap-2">
          <Input
            type="text"
            id="title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Title"
          />
       <input

            type="file"
            id="pdf"
            name="pdf"
            accept=".pdf"
            onChange={handleFileChange}
          />
        </div>

        {images.map((image) => (
          <Badge
            count="x"
            style={{ cursor: "pointer" }}
            className="mb-3"
            onClick={() => removeCloudinaryUpload(image.publicId)}
          >
            <Avatar
              key={image.publicId}
              src={image.imageUrl}
              size={350}
              shape="square"
            />
          </Badge>
        ))}

        <div>
          <input
            type="file"
            name="id"
            id="id"
            onChange={(e) => handleImagesChange(e)}
            hidden
          />
          <label
            htmlFor="id"
            className="py-[10px] px-[40px] bg-[#696969]  w-fit text-white rounded-md cursor-pointer "
          >
            {" "}
            {loading ? "Uploading ..." : "upload Img"}
          </label>
        </div>

        <div className="flex justify-end">
          <Button color="#EACC00" type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Publish"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewMagazine;
