import React from 'react'
import { Button } from '@mantine/core'
import moment from 'moment'
const ManageBlogCard = ({b,deleteBlogs}) => {
    const formattedCreatedAt = moment(b.createdAt).fromNow()
    
    return (
        <div>
            <div className='flex flex-col shadow-lg rounded-b-xl'>
                {b.images.map((i) =>(
         <img src={i.imageUrl} alt='/' />
                ))}
       
                <div className='flex flex-col gap-5 p-5'>
                    <h1 className='text-xl font-bold'>{b.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: b.description }} />
                    <div className='flex items-end justify-between'>
                        <span className='text-sm'>{formattedCreatedAt}</span>

                    </div>
                    <div className='flex items-center justify-end gap-3'>
                 
                        <Button variant='filled' color='red' onClick={() => deleteBlogs(b._id)}>Delete Blog</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageBlogCard
