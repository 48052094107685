import { Button, Input } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import ManageMagazineCard from "../../../components/cards/ManageMagazineCard";
import { deleteMagzine, getMagzines } from "../../../functions/magazine";
import Loading from "../../../components/Loading";
import toast from "react-hot-toast";

const MagazineManagement = () => {
  const [magazines, setMagaziens] = useState([]);
  const [loading,setLoading]= useState(false)
  const loadManagzine = useCallback(async () => {
    setLoading(true)
    const response = await getMagzines();
  setMagaziens(response)
  setLoading(false)
  },[])

  const magazineRemove = async (id) =>{
    const response = await deleteMagzine(id);
    toast.success(response.message)
    loadManagzine();
  }
  useEffect(() => {
    loadManagzine();
  }, [loadManagzine]);


  return (
    <div className="flex flex-col gap-10">
      <h1 className="text-xl font-medium">Magazines Management</h1>

      <div className="flex items-center justify-between w-full">
        <Input
          placeholder="Search magazine from title"
          rightSection={<IoSearchSharp />}
        />
        <Link to="/admin/Magazines_management/add_new">
          <Button variant="filled" color="#EACC00">
            Add New Magazine
          </Button>
        </Link>
      </div>

     
        {loading ? <Loading/> :
         <div className="grid grid-cols-1 gap-10 md:gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-10">
               {magazines.map((c) =>(
                <ManageMagazineCard c={c} magazineRemove={magazineRemove}/>
                      ))}
                    </div>
      }
 
    </div>
  );
};

export default MagazineManagement;
