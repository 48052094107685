import axios from "axios";
import toast from "react-hot-toast";
export const createAddvertismnet = async (
  token,
  first_name,
  last_name,
  bod,
  gender,
  ethnicity,
  religion,
  Food_prference,
  description,
  caste,
  civilstatus,
  height,
  country,
  state,
  images,
  education,
  profession,
  drinking,
  smoking,
  father_name,
  father_ethnicity,
  father_caste,
  father_religon,
  father_profession,
  mother_name,
  mother_ethnicity,
  mother_caste,
  mother_religon,
  mother_profession,
  birth_time,
  mobile,
  email,
  age
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/create/advertisemnet`,

      {
        first_name,
        last_name,
        bod,
        gender,
        ethnicity,
        religion,
        Food_prference,
        description,
        caste,
        civilstatus,
        height,
        country,
        state,
        images,
        education,
        profession,
        drinking,
        smoking,
        father_name,
        father_ethnicity,
        father_caste,
        father_religon,
        father_profession,
        mother_name,
        mother_ethnicity,
        mother_caste,
        mother_religon,
        mother_profession,
        birth_time,
        mobile,
        email,
        age,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const getMyAddvertisment = async (token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/getmy/add`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

/**get addvertismnet Admin */

export const getAdminAdvertsiments = async (token, addRole) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/get/adds`,
      { addRole },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const getAdminAdvertsimentById = async (token, addId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/get/add/${addId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};


export const updateMyAddvertismnet = async (token,addId, first_name,
  last_name,
  bod,
  gender,
  ethnicity,
  religion,
  caste,
  civilstatus,
  height,
  country,
  Food_prference,
  description,
  state,
  images,
  education,
  profession,
  drinking,
  smoking,
  father_name,
  father_ethnicity,
  father_caste,
  father_religon,
  father_profession,
  mother_name,
  mother_ethnicity,
  mother_caste,
  mother_religon,
  mother_profession,
  birth_time,
  mobile,
  email,
  age) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/user/add/${addId}`,
      {first_name,
        last_name,
        bod,
        gender,
        ethnicity,
        religion,
        caste,
        civilstatus,
        height,
        country,
        Food_prference,
        description,
        state,
        images,
        education,
        profession,
        drinking,
        smoking,
        father_name,
        father_ethnicity,
        father_caste,
        father_religon,
        father_profession,
        mother_name,
        mother_ethnicity,
        mother_caste,
        mother_religon,
        mother_profession,
        birth_time,
        mobile,
        email,
        age,},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};


export const updateAdvertismentStatus = async (token, addId, addRole) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/get/add/update/${addId}`,
      { addRole },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

/*------------------------------------------------------Home Pagination Load More Filtering------------------------------------------------------ */

export const getAddvertsimentHome = async (page) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/get/all/adds`,
      { page }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const feacthAddvertsiment = async (arg) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/filter`,
      { arg }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};


export const getAddvertismentByIdHome = async (addId,userId) =>{
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/adds/shows`,
      { addId,userId }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
}



export const deleteAddvertismentByIdHome = async (addId,userId) =>{
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/user/adds/shows`,
      { addId,userId }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
}



export const addsRemoveUser= async (token,addId) =>{
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API}/user/add/remove/${addId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
}
