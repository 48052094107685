import React from 'react';
import Loadinggif from '../assets/images/loading.gif';

function Loading() {
    return (
        <div className="flex items-center justify-center h-screen">
            <img src={Loadinggif} className="w-auto h-auto max-w-full max-h-full" alt="Loading" />
        </div>
    );
}

export default Loading;
