import axios from "axios";
import toast from "react-hot-toast";


export const createAccount = async (first_name,
  last_name,
  email,
  mobile,
  idImages,
  gender,
  password,
  country,
  state,) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/auth/register`,
        {
          first_name,
          last_name,
          email,
          mobile,
          idImages,
          gender,
          password,
          country,
          state,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.message);
    }
  };




export const sendOtpEmail = async (email) => {

    const response = await axios.post(
      `${process.env.REACT_APP_API}/auth/send/email`,
      {
     email
      }
    );
    return response.data;
 
};

export const myProfile = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/auth/my/profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const sendOtpMobile = async (mobile) => {

    const response = await axios.post(
      `${process.env.REACT_APP_API}/auth/send`,{
        mobile
      }
    );
    return response.data;
 
};



export const myProfileUpdate = async (token,first_name,last_name,email,mobile,idImg) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/auth/my/profile/update`,
      {first_name,last_name,email,mobile,idImg},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};




export const curentUser = async (token) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/auth/current-user`,null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;

};

export const cuurentAdmin = async (token) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/auth/current-admin`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
};



