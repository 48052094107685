import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Pagination } from "@mantine/core";
import PendingAdCard from "../../../components/cards/PendingAdCard";
import { getAdminAdvertsiments, updateAdvertismentStatus } from "../../../functions/addvertismentt";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading";

const AdManagement = () => {
  const [advertisementsReady, setAdvertisementsReady] = useState([]);
  const [advertisementsNotReady, setAdvertisementsNotReady] = useState([]);
  const [advertisementsDeclineAdd, setAdvertisementsDeclineAdd] = useState([]);
  const [loading, setLoading] = useState(false);
  ;
  const [currentPageReady, setCurrentPageReady] = useState(1);
  const [currentPageNotReady, setCurrentPageNotReady] = useState(1);
  const [currentPageDecline, setCurrentPageDecline] = useState(1);
  const { user } = useSelector((state) => state.user);

  const fetchAdvertisements = useCallback(async () => {
    setLoading(true);
    const response = await getAdminAdvertsiments(user.token, "Ready");
    setAdvertisementsReady(response); // Assuming the response is an array
    setLoading(false);
  }, [user.token])

  const fetchAdvertisementsNotReady = useCallback(async () => {
    setLoading(true);
    const response = await getAdminAdvertsiments(user.token, "Not Ready");
    setAdvertisementsNotReady(response);
    setLoading(false);
  }, [user.token,])

  const fetchAdvertisementsDeclient = useCallback(async () => {
    setLoading(true);
    const response = await getAdminAdvertsiments(user.token, "Decline");
    setAdvertisementsDeclineAdd(response);
    setLoading(false);
  }, [user.token])

  useEffect(() => {
    fetchAdvertisementsNotReady();
    fetchAdvertisementsDeclient();
    fetchAdvertisements();
  }, [fetchAdvertisementsNotReady, fetchAdvertisementsDeclient, fetchAdvertisements]);

  const submit = async (id, status) => {
    await updateAdvertismentStatus(user.token, id, status);
    fetchAdvertisementsNotReady();
    fetchAdvertisementsDeclient();
    fetchAdvertisements();
  };


  const renderAdvertisementPanel = (advertisements, currentPage, setCurrentPage) => (
    <div className="flex flex-col w-full gap-10 py-10">

      <div className="flex flex-col gap-5">
        {advertisements.map((c) => (
          <PendingAdCard add={c} submit={submit} key={c._id} />
        ))}
      </div>
      <Pagination
        total={Math.ceil(advertisements.length / 5)}
        value={currentPage}
        onChange={setCurrentPage}
        withGoTo
      />
    </div>
  );

  return (
    <div className="flex flex-col gap-10">
      <h1 className="text-xl font-medium">Ad Management</h1>
      {loading ? <Loading/> : (
        <div>
          <Tabs defaultValue="Pending" variant="pills" color="#D5BD81">
            <Tabs.List>
              <Tabs.Tab value="Pending">Pending Ads</Tabs.Tab>
              <Tabs.Tab value="Active">Active Ads</Tabs.Tab>
              <Tabs.Tab value="Decline">Decline Ads</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="Pending">
              {renderAdvertisementPanel(advertisementsNotReady, currentPageNotReady, setCurrentPageNotReady)}
            </Tabs.Panel>

            <Tabs.Panel value="Active">
              {renderAdvertisementPanel(advertisementsReady, currentPageReady, setCurrentPageReady)}
            </Tabs.Panel>

            <Tabs.Panel value="Decline">
              {renderAdvertisementPanel(advertisementsDeclineAdd, currentPageDecline, setCurrentPageDecline)}
            </Tabs.Panel>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default AdManagement;
