import axios from "axios";
import toast from "react-hot-toast";

export const getAllusers = async (verify) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/get/users`,
      {
        verify,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const showPakages = async (userId, verify) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/user/state/${userId}`,
      { verify }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const updateUserRole = async (userId, role) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/update/user/role/${userId}`,
      {
        role,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};
export const updateUserState = async (userId, verify) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/update/user/state/${userId}`,
      {
        verify,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const removeUser = async (userId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API}/admin/user/${userId}`,
    
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const getAllUserCount = async (token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/all/user/count`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};
export const getAllUserCountGender = async (token,gender) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/all/user/gender`,
      {gender},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};

export const getRunnigAdds = async (token) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/running`,
    null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message);
  }
};