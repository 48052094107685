import React, { useState } from "react";
import Header from "../../components/nav/Header";
import { Button, Modal, Radio} from "@mantine/core";
import { FaPhone } from "react-icons/fa6";
import { useDisclosure } from "@mantine/hooks";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Avatar } from "antd";
import axios from "axios";
import toast from "react-hot-toast";
import {PulseLoader} from "react-spinners"
import { useDispatch } from "react-redux";
import { register } from "../../app/features/userSlice";


const Register = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const [first_name, setFisrtName] = useState("");
  const [last_name, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirtDay] = useState("");
  const [gender, setGender] = useState("");
  const [idImg, setIdImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [country,setCountry] = useState('');
  const [state,setState] = useState('');
 const dispatch = useDispatch()
  // const submit = async () =>{
  //     const response = await register(first_name,last_name,mobile,email,bithday,gender)
  const navigate = useNavigate();
  const submit = async () => {
    setLoading(true);
    if(!first_name){ return toast.error('Please Enter first name')}else if(!last_name){
      return toast.error('Please enter last name')
    }else if(!email){
      return toast.error("Please enter email")
    }else if(!mobile){
      return toast.error("Please enter mobile")
    }else if (!gender){
      return toast.error("Please enter gender")
    }else if(!state){
      return toast.error("Please enter state")
    }else if(!country){
      return toast.error("Please enter country")
    }
    try {
      const res = await dispatch(register({ first_name,last_name,
        email,
        mobile,
        idImg,
        gender,
        country,
        state}))
      setLoading(false);
      console.log(res)
      if(res.payload.message === "register success"){
        toast.success("Register success");
        navigate("/user/advertisement/add_new");
        window.location.reload();
      }
      toast.error(res?.payload)

    
    

    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error.message)
    }
    

 
  };
  const handleCloudinaryUpload = async (e) => {
    const files = e.target.files;

  
    try {
        setLoading1(true);

        const formDataArray = Array.from(files).map((file) => {
            // Check file size (limit to 2MB)
            if (file.size > 5 * 1024 * 1024) {
                throw new Error("File size exceeds 2MB limit.");
            }
            // Check file type (accept only image types)
            const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/heic"];

            if (!allowedTypes.includes(file.type)) {
                throw new Error("Unsupported file type. Please upload an image.");
            }

            const formData = new FormData();
            formData.append("file", file);
            formData.append("upload_preset", "lvp9fvxm"); // Replace with your Cloudinary upload preset
            return formData;
        });

        const responses = await Promise.all(
            formDataArray.map((formData) =>
                axios.post("https://api.cloudinary.com/v1_1/dvhevyxgq/image/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
            )
        );

        setLoading1(false);

        const newVariants = responses.map((response) => ({
            imageUrl: response.data.secure_url,
            publicId: response.data.public_id,
        }));

        // Update state with the uploaded images
        setIdImg(prevImages => [...prevImages, ...newVariants]);

        console.log(newVariants);
    } catch (error) {
        setLoading1(false);
        console.log("Error uploading image to Cloudinary:", error.message);
        // Handle errors here (e.g., display error message to user)
    }
};


  const removeCloudinaryUpload = async (public_id) => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API}/user/removeImg`, {
        public_id,
      });
      setLoading(false);

      const newVariants = idImg.filter((image) => image.publicId !== public_id);
      setIdImg(newVariants);
    } catch (error) {
      console.log("Error removing image from Cloudinary:", error);
    }
  };

  return (
    <>
      <Header />

      <div className="flex flex-col justify-between w-full gap-10">
        <div className="flex flex-col gap-3 py-5 text-center w-full bg-[#E8B202] javanese_font">
          <span className="text-xl text-white">Welcome to</span>
          <h1 className="text-3xl text-white ">Thuhina.lk</h1>
        </div>

        <div className="flex justify-center w-full">
          <div className="relative rounded-lg shadow-lg  flex bg-[#F9F9F9] h-full  justify-between lg:w-1/2 w-full md:w-4/5">
            {/* <img src={Bg_top} alt="/" className="absolute top-0 left-0" />
            <img
              src={Bg_bottom}
              alt="/"
              className="absolute bottom-0 right-0"
            /> */}

            {/* <img src={loginimagere2} alt="/" className="absolute  h-[100%] w-auto" height={'100%'} /> */}

            <div className="z-50 flex flex-col w-full h-full gap-5 py-10">
              <h1 className="text-[#3B3B3B] text-center text-2xl font-medium">
                Create Account
              </h1>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">First Name (මුල් නම)</span>
                  <input
                    type="text"
                    className="w-full bg-transparent border-b outline-none"
                    placeholder="First Name"
                    value={first_name}
                    onChange={(e) => setFisrtName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Last Name (අවසන් නම)</span>
                  <input
                    type="text"
                    className="w-full bg-transparent border-b outline-none"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Mobile Number (දුරකථන අංකය)</span>
                  <div className="flex gap-5">
                    <input
                      type="text"
                      className="w-full bg-transparent border-b outline-none"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Email Address (ඊතැපැල් ලිපිනය)</span>
                  <input
                    type="email"
                    className="w-full bg-transparent border-b outline-none"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Birthday (උපන් දිනය)</span>
                  <input
                    className="w-full bg-transparent border-b outline-none"
                    type="date"
                    value={birthday}
                    onChange={(e) => setBirtDay(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Country (රට)</span>
                  <input
                  placeholder="country"
                    className="w-full bg-transparent border-b outline-none"
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">State (නගරය)</span>
                  <input
                  placeholder="state"
                    className="w-full bg-transparent border-b outline-none"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>


                <div className="flex flex-col gap-2 px-3">
                  <span className="text-[#3B3B3B]">Gender (ස්ත්‍රී පුරුෂ භාවය)</span>

                  <div className="flex gap-5">
                    <Radio
                    name="gender"
                      value="Male"
                      label="Male (පිරිමි)"
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <Radio
                    name="gender"
                      value="Female"
                      label="Female (ගැහැණු)"
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2 px-3 mt-5">
                  <span className="text-[#3B3B3B]">
                    Upload Your Identity Card Images (ඔබගේ හැඳුනුම්පත් පින්තූර ඇතුලත් කරන්න)
                  </span>

                  {idImg.map((image) => (
                    <Badge
                      count="x"
                      style={{ cursor: "pointer" }}
                      className="mb-3"
                      onClick={() => removeCloudinaryUpload(image.publicId)}
                    >
                      <Avatar
                        key={image.publicId}
                        src={image.imageUrl}
                        size={350}
                        shape="square"
                      />
                    </Badge>
                  ))}
                  <div className="flex gap-5 text-white mt-4 flex-col md:flex-row items-center">
                    <div>
                      <input
                        type="file"
                        name="id"
                        id="id"
                        onChange={(e) => handleCloudinaryUpload(e)}
                        hidden
                      />
                      <label
                        htmlFor="id"
                        className="bg-white border-2 text-yellow-500 border-yellow-500 p-2 rounded-lg hover:bg-yellow-500 hover:text-white cursor-pointer "
                      >
                        {" "}
                        {loading1 ? <PulseLoader size={6}/> : "Upload front image"}
                      </label>
                    </div>
                    <div>
                      <input
                        type="file"
                        name="id"
                        id="id"
                        onChange={(e) => handleCloudinaryUpload(e)}
                        hidden
                      />
                      <label
                        htmlFor="id"
                        className="bg-white border-2 text-yellow-500 border-yellow-500 p-2 rounded-lg hover:bg-yellow-500 hover:text-white cursor-pointer "
                      >
                        {" "}
                        {loading1 ? <PulseLoader size={6}/> : "Upload back image"}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-full mt-6">
                  <div className="w-1/3 md:w-1/5">
                    <Button
                      variant="filled"
                      color="#E8B202"
                      className="text-black"
                      onClick={() => submit()}
                    >
                      {loading ? <PulseLoader size={5}/> : "Create Account"}
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center w-full px-3">
                  <div
                    className="flex  gap-3 px-10 md:w-2/3 w-full py-2 items-center justify-center lg:w-2/2"
                    onClick={open}
                  >
                    <FaPhone />
                    <span className="font-bold cursor-pointer">
                      Call Us To Create Your Account
                    </span>
                  </div>
                </div>

                <Modal opened={opened} onClose={close} title="Contact Us ">
                  <h1>078 765 4076</h1>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full py-5 text-center text-[#595959]">
          <span className="">By signing up you are agreeing to our</span>
          <div className="flex justify-center w-full gap-1 text-center">
            <Link to="/privacy">
              <span>Privacy Policy </span>
            </Link>
            <span>and</span>
            <Link to="/terms&conditions">
              <span>Terms of Use.</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
