import axios from "axios";
import toast from "react-hot-toast";

export const createBlog = async (token,title,description,images) => {
  try {

    console.log(title,description,images)
    const response = await axios.post(
      `${process.env.REACT_APP_API}/admin/blog`,
      {
        title,description,images
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.error?.message);
  }
};

export const deleteBlog = async (token,blogId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/admin/blog/${blogId}`,null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.message);
    }
  };
  

  export const showBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/blogs`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.message);
    }
  };
  

  export const showOneBlog = async (blogId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/blog/${blogId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.message);
    }
  };
  