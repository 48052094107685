import React, { useEffect, useState } from "react";
import {  Input, Table, Tabs, Pagination } from "@mantine/core";
import { IoSearchSharp } from "react-icons/io5";
import { Popover } from "@mantine/core";
import {
  getAllusers,
  removeUser,
  updateUserRole,
  updateUserState,
} from "../../../functions/admin";
import moment from "moment";

import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import toast from "react-hot-toast";
import { GrAction } from "react-icons/gr";

const UserManagement = () => {
  const [verifyuser, setVerifyUser] = useState([]);
  const [notVerify, setNotverify] = useState([]);
  const [faildVerify, setVerifyUserFaild] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);

  useEffect(() => {
    verifyUser();
    NotVerify();
    FailVerify();
  }, []); // Adding an empty dependency array to prevent infinite loops

  const verifyUser = async () => {
    try {
      const response = await getAllusers("Verified");
      setVerifyUser(response || []);
    } catch (error) {
      console.error("Error fetching verified users:", error);
    }
  };

  const NotVerify = async () => {
    try {
      const response = await getAllusers("Not Verified");
      setNotverify(response || []);
    } catch (error) {
      console.error("Error fetching not verified users:", error);
    }
  };

  const FailVerify = async () => {
    try {
      const response = await getAllusers("Verified Faild");
      setVerifyUserFaild(response || []);
    } catch (error) {
      console.error("Error fetching failed verified users:", error);
    }
  };

  const updateUsertype = async (userId, role) => {
    try {
      const response = await updateUserRole(userId, role);
      verifyUser();
      NotVerify();
      FailVerify();
      toast.success(response.message);
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  const updateUserVerify = async (userId, verify) => {
    try {
      const response = await updateUserState(userId, verify);
      verifyUser();
      NotVerify();
      FailVerify();
      toast.success(response.message);
    } catch (error) {
      console.error("Error updating user verify state:", error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await removeUser(userId);
      verifyUser();
      NotVerify();
      FailVerify();
      toast.success(response.message);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const filteredNotVerifyUserRow = notVerify.filter((element) =>
    element?.first_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredVerifyUserRow = verifyuser.filter((element) =>
    element?.first_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredFaildVerifyUserRow = faildVerify.filter((element) =>
    element?.first_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div className="flex flex-col gap-10">
      <h1 className="text-xl font-medium">User Management</h1>

      <Tabs defaultValue="Pending" variant="pills" color="#EACC00">
        <Tabs.List>
          <Tabs.Tab value="Pending">Not Verify Users</Tabs.Tab>
          <Tabs.Tab value="Active">Verify Users</Tabs.Tab>
          <Tabs.Tab value="failed">Faild User</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Pending">
          <div className="flex flex-col w-full gap-10 py-10">
            <div className="flex items-center justify-between w-full">
              <Input
                placeholder="Search (First name)"
                rightSection={<IoSearchSharp />}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              {/* <Button variant="filled" color="#EACC00">
                Register New user
              </Button> */}
            </div>

            <div className="overflow-x-scroll bg-white scroll-smooth scrollbar-hide">
              <Table striped>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>First Name</Table.Th>
                    <Table.Th>Last Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Mobile</Table.Th>
                    <Table.Th>User Role</Table.Th>
                    <Table.Th>Verify status</Table.Th>
                    <Table.Th>Join Date</Table.Th>
                    <Table.Th>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {filteredNotVerifyUserRow
                    .slice(startIndex, endIndex)
                    .map((element) => (
                      <Table.Tr key={element._id}>
                        <Table.Td>{element.first_name}</Table.Td>
                        <Table.Td>{element.last_name}</Table.Td>
                        <Table.Td>{element.email}</Table.Td>
                        <Table.Td>{element.mobile}</Table.Td>
                        <Table.Td>
                          <select
                            onChange={(e) =>
                              updateUsertype(element._id, e.target.value)
                            }
                          >
                            <option value={element.role}>{element.role}</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                            <option value="blocked">Blocked</option>
                          </select>
                        </Table.Td>
                        <Table.Td>
                          <select
                            onChange={(e) =>
                              updateUserVerify(element._id, e.target.value)
                            }
                          >
                            <option value={element.usertype}>
                              {element.usertype}
                            </option>
                            <option value="Verified">Verified</option>
                            <option value="Verified Faild">
                              Verified Faild
                            </option>
                            <option value="Not Verified">Not Verified</option>
                          </select>
                        </Table.Td>
                        <Table.Td>
                          {moment(element.createdAt).format("YYYY-MM-DD")}
                        </Table.Td>

                        <Popover trapFocus position="bottom" withArrow shadow="md">
                          <Popover.Target>
                            <Table.Td>
                              <GrAction />
                              {element.action}
                            </Table.Td>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <div className="flex flex-col gap-3">
                              <h1
                                className="cursor-pointer hover:text-[#EACC00]"
                                onClick={open}
                              >
                                View User Profile
                              </h1>

                              <Modal
                                opened={opened}
                                onClose={close}
                                title="View user Details"
                              >
                                {element.first_name} <br />
                                {element.last_name} <br />
                                {element.email} <br />
                                {element.mobile} <br />
                                {!element.nic
                                  ? null
                                  : element.nic.map((n) => (
                                    <img src={n.imageUrl} alt={n.publicId} />
                                  ))}
                              </Modal>

                              <h1
                                className="cursor-pointer hover:text-[#EACC00]"
                                onClick={() => deleteUser(element._id)}
                              >
                                Delete User
                              </h1>
                            </div>
                          </Popover.Dropdown>
                        </Popover>
                      </Table.Tr>
                    ))}
                </Table.Tbody>
              </Table>
              <Pagination
                total={Math.ceil(filteredNotVerifyUserRow.length / itemsPerPage)}
                itemsPerPage={itemsPerPage}
                page={currentPage}
                onChange={setCurrentPage}
                className="ml-[40%] lg:ml-[40%] my-2"
              />
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="Active">
          <div className="flex flex-col w-full gap-10 py-10">
            <div className="flex items-center justify-between w-full">
              <Input
                placeholder="Search users by First Name"
                rightSection={<IoSearchSharp />}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              {/* <Button variant="filled" color="#EACC00">
                Register New user
              </Button> */}
            </div>

            <div className="overflow-x-scroll bg-white scroll-smooth scrollbar-hide">
              <Table striped>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>First Name</Table.Th>
                    <Table.Th>Last Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Mobile</Table.Th>
                    <Table.Th>User Role</Table.Th>
                    <Table.Th>Verify status</Table.Th>
                    <Table.Th>Join Date</Table.Th>
                    <Table.Th>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {filteredVerifyUserRow.slice(startIndex, endIndex).map((element) => (
                    <Table.Tr key={element._id}>
                      <Table.Td>{element.first_name}</Table.Td>
                      <Table.Td>{element.last_name}</Table.Td>
                      <Table.Td>{element.email}</Table.Td>
                      <Table.Td>{element.mobile}</Table.Td>
                      <Table.Td>
                        <select
                          onChange={(e) =>
                            updateUsertype(element._id, e.target.value)
                          }
                        >
                          <option value={element.role}>{element.role}</option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                          <option value="blocked">Blocked</option>
                        </select>
                      </Table.Td>
                      <Table.Td>
                        <select
                          onChange={(e) =>
                            updateUserVerify(element._id, e.target.value)
                          }
                        >
                          <option value={element.usertype}>
                            {element.usertype}
                          </option>
                          <option value="Verified">Verified</option>
                          <option value="Verified Faild">Verified Faild</option>
                          <option value="Not Verified">Not Verified</option>
                        </select>
                      </Table.Td>
                      <Table.Td>
                        {moment(element.createdAt).format("YYYY-MM-DD")}
                      </Table.Td>

                      <Popover trapFocus position="bottom" withArrow shadow="md">
                        <Popover.Target>
                          <Table.Td>
                            <GrAction />
                            {element.action}
                          </Table.Td>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <div className="flex flex-col gap-3">
                            <h1
                              className="cursor-pointer hover:text-[#EACC00]"
                              onClick={open}
                            >
                              View User Profile
                            </h1>

                            <Modal
                              opened={opened}
                              onClose={close}
                              title="View user Details"
                            >
                              {element.first_name} <br />
                              {element.last_name} <br />
                              {element.email} <br />
                              {element.mobile} <br />
                              {!element.nic
                                ? null
                                : element.nic.map((n) => (
                                  <img src={n.imageUrl} alt={n.publicId} />
                                ))}
                            </Modal>

                            <h1
                              className="cursor-pointer hover:text-[#EACC00]"
                              onClick={() => deleteUser(element._id)}
                            >
                              Delete User
                            </h1>
                          </div>
                        </Popover.Dropdown>
                      </Popover>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
              <Pagination
                total={Math.ceil(filteredVerifyUserRow.length / itemsPerPage)}
                itemsPerPage={itemsPerPage}
                page={currentPage}
                onChange={setCurrentPage}
                className="ml-[40%] lg:ml-[40%] my-2"
              />
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="failed">
          <div className="flex flex-col w-full gap-10 py-10">
            <div className="flex items-center justify-between w-full">
              <Input
                placeholder="Search users by First Name"
                rightSection={<IoSearchSharp />}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
              {/* <Button variant="filled" color="#EACC00">
                Register New user
              </Button> */}
            </div>

            <div className="overflow-x-scroll bg-white scroll-smooth scrollbar-hide">
              <Table striped>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>First Name</Table.Th>
                    <Table.Th>Last Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Mobile</Table.Th>
                    <Table.Th>User Role</Table.Th>
                    <Table.Th>Verify status</Table.Th>
                    <Table.Th>Join Date</Table.Th>
                    <Table.Th>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {filteredFaildVerifyUserRow.slice(startIndex, endIndex).map((element) => (
                    <Table.Tr key={element._id}>
                      <Table.Td>{element.first_name}</Table.Td>
                      <Table.Td>{element.last_name}</Table.Td>
                      <Table.Td>{element.email}</Table.Td>
                      <Table.Td>{element.mobile}</Table.Td>
                      <Table.Td>
                        <select
                          onChange={(e) =>
                            updateUsertype(element._id, e.target.value)
                          }
                        >
                          <option value={element.role}>{element.role}</option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                          <option value="blocked">Blocked</option>
                        </select>
                      </Table.Td>
                      <Table.Td>
                        <select
                          onChange={(e) =>
                            updateUserVerify(element._id, e.target.value)
                          }
                        >
                          <option value={element.usertype}>
                            {element.usertype}
                          </option>
                          <option value="Verified">Verified</option>
                          <option value="Verified Faild">Verified Faild</option>
                          <option value="Not Verified">Not Verified</option>
                        </select>
                      </Table.Td>
                      <Table.Td>
                        {moment(element.createdAt).format("YYYY-MM-DD")}
                      </Table.Td>

                      <Popover trapFocus position="bottom" withArrow shadow="md">
                        <Popover.Target>
                          <Table.Td>
                            <GrAction />
                            {element.action}
                          </Table.Td>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <div className="flex flex-col gap-3">
                            <h1
                              className="cursor-pointer hover:text-[#EACC00]"
                              onClick={open}
                            >
                              View User Profile
                            </h1>

                            <Modal
                              opened={opened}
                              onClose={close}
                              title="View user Details"
                            >
                              {element.first_name} <br />
                              {element.last_name} <br />
                              {element.email} <br />
                              {element.mobile} <br />
                              {!element.nic
                                ? null
                                : element.nic.map((n) => (
                                  <img src={n.imageUrl} alt={n.publicId} />
                                ))}
                            </Modal>

                            <h1
                              className="cursor-pointer hover:text-[#EACC00]"
                              onClick={() => deleteUser(element._id)}
                            >
                              Delete User
                            </h1>
                          </div>
                        </Popover.Dropdown>
                      </Popover>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
              <Pagination
                total={Math.ceil(filteredFaildVerifyUserRow.length / itemsPerPage)}
                itemsPerPage={itemsPerPage}
                page={currentPage}
                onChange={setCurrentPage}
                className="ml-[40%] lg:ml-[40%] my-2"
              />
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default UserManagement;
