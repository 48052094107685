import React, { useState } from 'react'
import { FaPhoneVolume } from "react-icons/fa6";
import { MdAttachEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import {  Button } from '@mantine/core';
import {PulseLoader} from "react-spinners"
import { ContactUs } from '../functions/contact.js';
import toast from 'react-hot-toast';

const Contact = () => {
    const [first_name,setFirstName] = useState('');
    const [last_Name,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('')
    const [mobile,setMobile] = useState('')
    const [loading,setLoading] = useState(false)

    const sendMessage = async () =>{
   
        try {
            setLoading(true)
            const response = await  ContactUs(first_name,last_Name,email,message,mobile) 
            setLoading(false)
            setFirstName('')
            setLastName('')
            setEmail('')
            setMessage('')
            setMobile('')
            toast.success(response.message)
        } catch (error) {
            setLoading(false)
            toast.error(error.response.data.message)
        }
   
    
    }
    return (
        <div className='flex flex-col w-full px-3 pb-10 md:px-10 lg:px-16 xl:px-20'>
            <div className='flex flex-col w-full gap-3 py-10 text-center'>
                <h1 className='text-3xl font-bold'>Contact Us</h1>
                <span className='text-[#696969]'>Any question or remarks? Just write us a message!</span>
            </div>

            <div className='flex flex-col w-full gap-0 md:flex-row md:gap-0 mt-7'>

                <div className='w-full rounded-md md:w-2/5 bg-[#696969] flex flex-col p-5  md:p-10 justify-between gap-3'>
                    <div className='flex flex-col w-full gap-3'>
                        <h1 className='text-2xl font-bold text-white'>Contact Information</h1>
                        <span className='text-[#e6e6e6]'>Any question or remarks? Just write us a message!</span>
                        <span className='text-[#e6e6e6] gap-1'>Thank you for reaching out to us! We value your feedback, inquiries, and suggestions. Please feel free to contact us using the information below .</span>
                    </div>

                    <div className='flex flex-col w-full gap-2 text-white'>
                        <div className='flex items-center w-full gap-2'>
                            <FaPhoneVolume />
                            <span>+94 78 765 4076</span>
                        </div>
                        <div className='flex items-center w-full gap-2'>
                            <MdAttachEmail />
                            <span>lkthuhina@gmail.com</span>
                        </div>
                        <div className='flex items-center w-full gap-2'>
                            <IoLocationSharp />
                            <span>No 26-1 ,Kumbukwewa , Kekirawa</span>
                        </div>
                    </div>

                    
                </div>

                <div className='flex flex-col w-full gap-10 p-5 md:p-10 md:w-3/5'>
                    <div className='grid grid-cols-1 gap-10 md:gap-5 md:grid-cols-2'>
                        <div className='flex flex-col gap-2'>
                            <span className='font-light text-black'>First Name</span>
                            <input type='text' value={first_name} onChange={(e) => setFirstName(e.target.value)} className='border-b-2 outline-none' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <span className='font-light text-black'>Last Name</span>
                            <input type='text' value={last_Name} onChange={(e) => setLastName(e.target.value)} className='border-b-2 outline-none' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='font-light text-black'>Email</span>
                        <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='border-b-2 outline-none' />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='font-light text-black'>Mobile</span>
                        <input type='number' value={mobile} onChange={(e) =>setMobile(e.target.value)} className='border-b-2 outline-none' />
                    </div>
                    
                    <div className='flex flex-col gap-2'>
                        <span className='font-light text-black'>Message</span>
                        <textarea type='text' value={message} onChange={(e) =>setMessage(e.target.value)} className='border-b-2 outline-none' />
                    </div>
                    <div className='flex justify-end'>
                        {loading ?  <Button variant='filled' color='#E8B202' > <PulseLoader size={5} /></Button>: <Button variant='filled' color='#E8B202' onClick={() => sendMessage()}>Send Message</Button> }
                       
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Contact
